import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = '深圳饭碗农业物联网科技有限公司';
  ngOnInit(): void {
    const hostName = window.location.hostname;
    if (hostName.includes('iagro.net')) {
      this.title = '饭碗智农';
    } else if (hostName.includes('iagro.club')) {
      this.title = '智慧家庭菜园';
    } else if (hostName.includes('iagro.info')) {
      this.title = '饭碗科技';
    } else if (hostName.includes('fine1.com.cn')) {
      this.title = '深圳饭碗农业物联网科技有限公司';
    } else if (hostName.includes('fine1.cn')) {
      this.title = '深圳饭碗农业物联网科技有限公司';
    } else if (hostName.includes('iagro.xyz')) {
      this.title = '智慧农服';
    } else if (hostName.includes('fineone.xyz')) {
      this.title = '深圳饭碗农业物联网科技有限公司';
    }
    document.title = this.title;
  }
}
