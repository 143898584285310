import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VegetablesComponent } from './vegetables.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [VegetablesComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class VegetablesModule { }
