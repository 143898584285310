import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hardware-price',
  templateUrl: './hardware-price.component.html',
  styleUrls: ['./hardware-price.component.css'],
})
export class HardwarePriceComponent implements OnInit {
  isOpen = true;
  selectData = '方案一';
  secondPrice;
  therePrice;
  fivePrice;
  eightPrice;
  firstPrice;
  firstPriceTotal;
  secondPriceTotal;
  therePriceTotal;
  fivePriceTotal;
  eightPriceTotal;

  firstData = [
    {
      isSelect: true,
      firstYear: 1,
      firstPrice: 2000.0,
      firstPriceTotal: 2000.0,
    },
    {
      isSelect: false,
      firstYear: 2,
      firstPrice: 1700.0,
      firstPriceTotal: 3400.0,
    },
    {
      isSelect: false,
      firstYear: 3,
      firstPrice: 1600.0,
      firstPriceTotal: 4800.0,
    },

    {
      isSelect: false,
      firstYear: 5,
      firstPrice: 1400.0,
      firstPriceTotal: 7000.0,
    },

    {
      isSelect: false,
      firstYear: 8,
      firstPrice: 1100.0,
      firstPriceTotal: 8800.0,
    },
  ];
  currentData: any;
  currentYear: any;
  currentPriceTotal: any;
  currentFirstPrice: any;
  constructor(public router: Router) {}

  defuaultPrice = 1500;
  ngOnInit() {
    this.init();
    this.selectProgramme('方案一');
  }
  init() {
    if ($('.btn-zd').length) {
      $(document).on('scroll', function () {
        var c = $(document).scrollTop();
        if (c > 30) {
          $('.btn-zd').css('display', 'block');
        } else {
          $('.btn-zd').css('display', 'none');
        }
      });
      $('.btn-zd').click(function () {
        $('html,body').animate({ scrollTop: 0 }, 'slow');
      });
    }

    if ($('#Quick-navigation').length) {
      var a = $('#Quick-navigation'),
        b = a.offset();
      $(document).on('scroll', function () {
        var c = $(document).scrollTop();
        if (b.top <= c) {
          a.css({ position: 'fixed', top: '98px' });
        } else {
          a.css({ position: 'relative', top: '0' });
        }
      });
    }

    if ($('#xzbb .list .list-item').length) {
      $('#xzbb .list .list-item').click(function () {
        $(this).addClass('selected').siblings().removeClass('selected');
      });
    }

    if ($('#scxz .list .list-item').length) {
      $('#scxz .list .list-item').click(function () {
        $(this).addClass('selected').siblings().removeClass('selected');
      });
    }

    if ($('#zffs .list .list-item').length) {
      $('#zffs .list .list-item').click(function () {
        $(this).addClass('selected').siblings().removeClass('selected');
      });
    }

    if ($('.dialog-close').length) {
      $('.dialog-bg,.dialog-close').click(function () {
        $('.dialog').fadeOut('fast');
        $('.dialog-bg').fadeOut('fast');
      });
    }
  }

  selectsData(item) {
    console.log('item', item);
    this.currentYear = item['firstYear'];
    this.currentPriceTotal = item['firstPriceTotal'];
    this.currentFirstPrice = item['firstPrice'];

    for (let i = 0; i < this.firstData.length; i++) {
      if (item['firstPrice'] == this.firstData[i]['firstPrice']) {
        this.firstData[i]['isSelect'] = true;
      } else {
        this.firstData[i]['isSelect'] = false;
      }
      console.log('firstData', this.firstData);
    }
    // }
  }

  selectProgramme(item) {
    this.selectData = item;

    if (this.selectData == '方案二') {
      for (let i = 0; i < this.firstData.length; i++) {
        if (
          this.firstData[i]['firstPrice'] == 2000 ||
          this.firstData[i]['firstPrice'] == 1700 ||
          this.firstData[i]['firstPrice'] == 1600 ||
          this.firstData[i]['firstPrice'] == 1400 ||
          this.firstData[i]['firstPrice'] == 1100 ||
          
          this.firstData[i]['firstPriceTotal'] == 2000 ||
          this.firstData[i]['firstPriceTotal'] == 3400 ||
          this.firstData[i]['firstPriceTotal'] == 3600 ||
          this.firstData[i]['firstPriceTotal'] == 5250 ||
          this.firstData[i]['firstPriceTotal'] == 4125
        ) {
          this.firstData[i]['firstPrice'] =
            this.firstData[i]['firstPrice'] + 1000;
          this.firstData[i]['firstPriceTotal'] =
            this.firstData[i]['firstPriceTotal'] + 1000;

          console.log('方案二', this.firstData);
        }
        if (this.firstData[i]['isSelect'] == true) {
          this.currentFirstPrice = this.firstData[i]['firstPrice'];
          this.currentPriceTotal = this.firstData[i]['firstPriceTotal'];
        }
      }

      // this.defuaultPrice = this.defuaultPrice + 1000;

      // this.firstPrice = this.defuaultPrice.toFixed(2);
      // this.firstPriceTotal = (this.firstPrice * 1).toFixed(2);

      // this.secondPrice = (this.defuaultPrice * 0.85).toFixed(2);
      // this.secondPriceTotal = (this.secondPrice * 2).toFixed(2);

      // this.therePrice = (this.defuaultPrice * 0.8).toFixed(2);
      // this.therePriceTotal = (this.therePrice * 3).toFixed(2);

      // this.fivePrice = (this.defuaultPrice * 0.7).toFixed(2);
      // this.fivePriceTotal = (this.fivePrice * 5).toFixed(2);

      // this.eightPrice = (this.defuaultPrice * 0.55).toFixed(2);
      // this.eightPriceTotal = (this.eightPrice * 5).toFixed(2);
      // this.eightPriceTotal

      console.log();
      // this.selectYear(this.defuaultPrice);
    } else {
      if (this.selectData == '方案一') {
        for (let i = 0; i < this.firstData.length; i++) {
          if (
            this.firstData[i]['firstPrice'] == 2500 ||
            this.firstData[i]['firstPrice'] == 2275 ||
            this.firstData[i]['firstPrice'] == 2200 ||
            this.firstData[i]['firstPrice'] == 2050 ||
            this.firstData[i]['firstPrice'] == 1825 ||
            
            this.firstData[i]['firstPriceTotal'] == 2500 ||
            this.firstData[i]['firstPriceTotal'] == 3550 ||
            this.firstData[i]['firstPriceTotal'] == 4600 ||
            this.firstData[i]['firstPriceTotal'] == 6250 ||
            this.firstData[i]['firstPriceTotal'] == 5125
          ) {
            this.firstData[i]['firstPrice'] =
              this.firstData[i]['firstPrice'] - 1000;
            this.firstData[i]['firstPriceTotal'] =
              this.firstData[i]['firstPriceTotal'] - 1000;
          }
          if (this.firstData[i]['isSelect'] == true) {
            this.currentFirstPrice = this.firstData[i]['firstPrice'];
            this.currentPriceTotal = this.firstData[i]['firstPriceTotal'];
          }
          console.log('方案一', this.firstData);
        }
      }

      // this.defuaultPrice = 1500;
      // // this.selectYear(item)
      // this.firstPrice = this.defuaultPrice.toFixed(2);
      // this.firstPriceTotal = (this.firstPrice * 1).toFixed(2);
      // this.secondPrice = (this.defuaultPrice * 0.85).toFixed(2);
      // this.secondPriceTotal = (this.secondPrice * 2).toFixed(2);
      // this.therePrice = (this.defuaultPrice * 0.8).toFixed(2);
      // this.therePriceTotal = (this.therePrice * 3).toFixed(2);
      // this.fivePrice = (this.defuaultPrice * 0.7).toFixed(2);
      // this.fivePriceTotal = (this.fivePrice * 5).toFixed(2);
      // this.eightPrice = (this.defuaultPrice * 0.55).toFixed(2);
      // this.eightPriceTotal = (this.eightPrice * 5).toFixed(2);
    }
  }

  // selectYear(item) {
  //   if (item == '1年') {
  //     this.firstPrice = this.defuaultPrice;
  //     this.firstPriceTotal=this.firstPrice * 1
  //   } else if (item == '2年') {
  //     this.secondPrice = this.defuaultPrice * 0.85;
  //     this.secondPriceTotal=this.secondPrice * 2
  //     console.log('secondPrice', this.secondPrice);
  //   } else if (item == '3年') {
  //     this.therePrice = this.defuaultPrice * 0.8;
  //     this.therePriceTotal=this.therePrice * 3
  //   } else if (item == '5年') {
  //     this.fivePrice = this.defuaultPrice * 0.7;
  //     this.fivePriceTotal=this.fivePrice * 5;
  //   } else if (item == '8年') {
  //     this.eightPrice = this.defuaultPrice * 0.55;
  //     this.eightPriceTotal=this.eightPrice * 5
  //   }
  // }
  index() {
    this.router.navigate(['/index']);
  }
  myAddress() {
    this.router.navigate(['/myAddress']);
  }
  liveFunction() {
    this.router.navigate(['/liveFunction']);
  }
  materialManageComponent() {
    this.router.navigate(['/materialManageComponent']);
  }
  price() {
    this.router.navigate(['/price']);
  }
  hardwareService() {
    this.router.navigate(['/hardwareService']);
  }
  about() {
    this.router.navigate(['/about']);
  }
  dataAnalysis() {
    this.router.navigate(['/dataAnalysis']);
  }
  waterFertilizer() {
    this.router.navigate(['/waterFertilizer']);
  }
  soilMoisture() {
    this.router.navigate(['/soilMoisture']);
  }
  climateMonitoring() {
    this.router.navigate(['/climateMonitoring']);
  }
  coldtracking() {
    this.router.navigate(['/coldtracking']);
  }
  traceability() {
    this.router.navigate(['/traceability']);
  }
  buyBow() {
    this.router.navigate(['/buyBow']);
  }

  continuePayment() {
    this.router.navigate(['/continuePayment']);
  }
  vegetables() {
    this.router.navigate(['/vegetables']);
  }
  fruitTree() {
    this.router.navigate(['/fruitTree']);
  }

  breed() {
    this.router.navigate(['/breed']);
  }
  customerService() {
    this.router.navigate(['/customerService']);
  }
  helpCenter() {
    this.router.navigate(['/helpCenter']);
  }
  userAgreement() {
    this.router.navigate(['/userAgreement']);
  }
  search() {
    this.router.navigate(['/search']);
  }
  privacyAgreement() {
    this.router.navigate(['/privacyAgreement']);
  }
  forgetPassword() {
    this.router.navigate(['/forgetPassword']);
  }

  // openDiaolg(){
  //   console.log("Asdf");
  //   this.isOpen=true;
  // }

  // //弹窗
  openDiaolg(dialog, dialog_bg) {
    $(dialog_bg).fadeIn('fast');
    $(dialog).fadeIn('fast');
  }

  changeDiaolg(hide_dialog, show_dialog) {
    $(hide_dialog).hide();
    $(show_dialog).show();
  }
  // changeDiaolgS(){
  //   $(hide_dialog).hide();
  //   $(show_dialog).show();
  // }
}
