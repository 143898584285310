
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AxiosService } from './axios.service';
import { HttpClientModule } from '@angular/common/http';
import { CopyRightComponent } from '../copy-right/copy-right.component';


// const PIPES = [AxiosService];

@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule],
  // declarations: [...PIPES],
  // providers: [PIPES],
  declarations: [CopyRightComponent],
  providers: [
    { provide: AxiosService, useClass: AxiosService },

  ],

  exports: [CommonModule, RouterModule, CopyRightComponent],
})
export class SharedModule { }
