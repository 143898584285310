<header class="header">
	<ul>
		<div class="logo"></div>
		<div class="menu">
			<li class="menu-item"><a class="menu-link"  href="javascript:void(0)"  (click)="index()">首页</a></li>
			<li class="menu-item drop-down-menu">
				<a class="menu-link" href="javascript:void(0)">产品功能</a>
				<div class="drop-down-menu-wrap">
					<div class="drop-down-menu-box">
						<div class="sub-menu">
							<ul>
                                <li><a href="javascript:void(0)" (click)="materialManageComponent()">
                                    <p class="name">物料管理</p>
                                    <p>扫码即可出入库</p>
                                </a></li>
                            <li><a href="javascript:void(0)" (click)="liveFunction()">
                                    <p class="name">直播功能</p>
                                    <p>可对接各大直播平台</p>
                                </a></li>
                            <li><a href="javascript:void(0)" (click)="dataAnalysis()">
                                    <p class="name">数据分析</p>
                                    <p>可导出生产数据报表</p>
                                </a></li>
                            <li><a href="javascript:void(0)" (click)="waterFertilizer()">
                                    <p class="name">水肥一体</p>
                                    <p>通过系统控制水肥系统</p>
                                </a></li>
                            <li><a href="javascript:void(0)" (click)="soilMoisture()">
                                    <p class="name">土壤墒情</p>
                                    <p>监控土壤墒情</p>
                                </a></li>
                            <li><a href="javascript:void(0)" (click)="climateMonitoring()">
                                    <p class="name">气候监控</p>
                                    <p>随时随地都能查看</p>
                                </a></li>
                            <li><a href="javascript:void(0)" (click)="coldtracking()">
                                    <p class="name">冷链跟踪</p>
                                    <p>可查看冷链车相关情况</p>
                                </a></li>
                            <!-- <li><a href="dpjk.html">
                                    <p class="name">大屏监控功能</p>
                                    <p>通过大屏即知生产情况</p>
                                </a></li> -->
                            <li><a href="javascript:void(0)" (click)="traceability()">
                                    <p class="name">溯源功能</p>
                                    <p>可生成溯源二维码</p>
                                </a></li>
							</ul>
						</div>
					</div>
				</div>
			</li>
			<li class="menu-item drop-down-menu">
				<a class="menu-link" href="javascript:void(0)">应用方案</a>
				<div class="drop-down-menu-wrap">
					<div class="drop-down-menu-box">
						<div class="sub-menu">
							<ul>
                                <li class="selected"><a href="javascript:void(0)" (click)="vegetables()">
                                    <p class="name">蔬菜</p>
                                    <p>从容生产</p>
                                </a></li>
                            <li><a href="javascript:void(0)" (click)="fruitTree()">
                                    <p class="name">果树</p>
                                    <p>高品质果树</p>
                                </a></li>
                            <li><a href="javascript:void(0)" (click)="breed()">
                                    <p class="name">养殖</p>
                                    <p>智慧养殖</p>
                                </a></li>
							</ul>
						</div>
					</div>
				</div>
			</li>
            <li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="hardwareService()">硬件服务</a></li>
            <!-- <li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="price()">价格</a></li> -->
		</div>
		<div class="right">
            <a  href="javascript:void(0)" (click)="search()" class="btn-search">搜索</a>
			<a href="javascript:void(0)" class="btn-register" onClick="openDiaolg('#dialog-reg','.dialog-bg')">免费注册</a>
			<!-- 我的s -->
			<div class="mine">
				<div class="avatar"><i class="icon-avatar"></i><i class="icon-arrow"></i></div>
				<div class="mine-popup">
					<div class="mine-popup-wrap">
						<div class="user-info">
							<p class="tel">13352928035</p>
							<p class="version">免费版<span class="btn-upgrade">升级</span></p>
						</div>
						<div class="nav">
							<a href="javascript:void(0)" (click)="myOrder()" class="nav-item"><i class="icon icon-dd"></i>我的订单</a>
							<a  href="javascript:void(0)" (click)="myAddress()"  class="nav-item"><i class="icon icon-dz"></i>我的地址</a>
							<a href="javascript:void(0)"   (click)="accountInfo()" class="nav-item"><i class="icon icon-zh"></i>账户信息</a>
							<a href="javascript:void(0)" (click)="index()" class="nav-item" id="aqtc"><i class="icon icon-tc"></i>安全退出</a>
						</div>
					</div>
				</div>
			</div>
			<!-- 我的e -->
		</div>
	</ul>
</header>
<main class="ysxy bg-light-green">
	<div class="w-fixed">
		<div class="container">
			<h1 class="title">用户协议</h1>
			<p class="date">更新日期：2021年08月16日<br/>生效日期：2021年08月16日</p>
			<p>重要须知<br>在此特别提醒用户认真阅读、充分理解本《软件许可及服务协议》（下称《协议》）---用户应认真阅读、充分理解本《协议》中各条款，包括免除或者限制责任的免责条款及对用户的权利限制条款。请您审慎阅读并选择接受或不接受本《协议》（未成年人应在法定监护人陪同下阅读）。除非您接受本《协议》所有条款，否则您无权下载、安装或使用本软件及其相关服务。您的下载、安装、使用、帐号获取和登录等行为将视为对本《协议》的接受，并同意接受本《协议》各项条款的约束。</p>
			<p>本《协议》是您（下称“用户”）与深圳饭碗农业物联网科技有限公司（下称“本司”）及其运营合作单位（下称“合作单位”）之间关于用户下载、安装、使用、复制;使用、管理帐号；以及使用相关服务所订立的协议。本《协议》描述与用户之间关于“软件”许可使用及服务相关方面的权利义务。“用户”是指通过提供的获取软件授权和帐号注册的途径获得软件产品及号码授权许可以及使用相关服务的个人或组织。本《协议》可随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知。用户可重新下载安装本软件或网站查阅最新版协议条款。在修改《协议》条款后，如果用户不接受修改后的条款，请立即停止使用提供的软件和服务，用户继续使用提供的软件和服务将被视为已接受了修改后的协议。</p>
			<h2 class="f-bold">一.知识产权声明</h2>
			<p>1.1 本“软件”是深圳饭碗农业物联网科技有限公司开发。“软件”的一切版权、商标权、专利权、商业秘密等知识产权，以及与“软件”相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料、或电子文档等均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其他知识产权法律法规的保护，除涉及第三方授权的软件或技术外，享有上述知识产权。</p>
			<p>1.2 未经书面同意，用户不得为任何营利性或非营利性的目的自行实施、利用、转让或许可任何三方实施、利用、转让上述知识产权，保留追究上述未经许可行为的权利。</p>
			<h2 class="f-bold">二.“软件”授权范围</h2>
			<p>2.1 用户不得对该软件或者该软件运行过程中释放到任何计算机终端内存中的数据及该软件运行过程中客户端与服务器端的交互数据进行复制、更改、修改、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经授权的第三方工具/服务接入本“软件”和相关系统。</p>
			<p>2.2 保留权利：未明示授权的其他一切权利仍归所有，用户使用其他权利时须另外取得的书面同意。</p>
			<h2 class="f-bold">三.帐号要使用本“软件”</h2>
			<p>用户需填写并提交个人或组织资料注册帐号。作为帐号注册过程的一部分，用户必须同意： <br>(1) 按照帐号注册过程的提示信息，提供有关您本人的真实信息（此类信息必须保持最新、完整且准确）；</p>
			<p>(2) 根据需要维护并更新注册人信息，使之始终保持最新、完整且准确。如果您未满18周岁，应在法定监护人陪同下阅读并提交帐号注册。</p>
			<p>3.1用户可以通过注册帐号使用提供的各种服务。服务包括但不限于PC端及手持终端软件等可以通过帐号登录或使用的服务，保留对公司未来服务改变和说明的权利。无论用户通过何种方式获得帐号，均受本协议约束。用户通过帐号使用的服务时，须同时遵守各项服务的服务条款。</p>
			<p>3.2 帐号的所有权归平台所有，用户完成申请注册手续后，获得帐号的使用权。</p>
			<p>3.3 帐号使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。如果发现使用者并非帐号初始注册人，有权在未经通知的情况下回收该帐号而无需向该帐号使用人承担法律责任，由此带来的包括并不限于用户通讯中断、用户资料等清空等损失由用户自行承担。禁止用户私下有偿或无偿转让帐号，以免因帐号问题产生纠纷，用户应当自行承担因违反此要求而遭致的任何损失，同时保留追究上述行为人法律责任的权利。</p>
			<p>3.4 用户承担帐号与密码的保管责任，并就其帐号及密码项下之一切活动负全部责任。用户须重视帐号密码和公开邮箱的密码保护。用户同意如发现他人未经许可使用其帐号时立即通知。</p>
			<p>3.5 用户帐号在丢失或遗忘密码后，须遵照的申诉途径及时申诉请求找回帐号。用户应提供能增加帐号安全性的个人密码保护资料。用户可以凭初始注册资料及个人密码保护资料填写申诉向申请找回帐号，的密码找回机制仅负责识别申诉单上所填资料与系统记录资料的正确性，而无法识别申诉人是否系真正帐号权使用人。对用户因被他人冒名申诉而致的任何损失，不承担任何责任，用户知晓帐号及密码保管责任在于用户，并不承诺帐号丢失或遗忘密码后用户一定能通过申诉找回帐号。</p>
			<p>3.6 用户注册帐号后如果长期不使用，有权回收帐号，以免造成资源浪费，由此带来的包括并不限于用户通信中断、用户资料、邮件等损失由用户自行承担。</p>
			<h2 class="f-bold">四.“软件”使用用户在遵守法律及本《协议》的前提下可依本《协议》使用本“软件”</h2>
			<p>用户无权实施包括但不限于下列行为：<br>4.1 用户通过非开发、授权或认可的三方兼容软件、系统登录或使用软件及服务，用户针对公司的软件和服务使用非公司开发、授权或认证的插件和外挂；</p>
			<p>4.2 删除本“软件”及其他副本上所有关于版权的信息、内容；</p>
			<p>4.3 对本“软件”进行反向工程、反向汇编、反向编译等；</p>
			<p>4.4 对于本“软件”相关信息等，未经书面同意，用户擅自实施包括但不限于下列行为：使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、擅自借助本软件发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等。</p>
			<p>4.5 利用本“软件”发表、传送、传播、储存违反国家法律、危害国家安全、祖国统一、社会稳定、公序良俗的内容，或任何不当的、侮辱诽谤的、淫秽的、暴力的及任何违反国家法律法规政策的内容。</p>
			<p>4.6 利用本“软件”发表、传送、传播、储存侵害他人知识产权、商业秘密权等合法权利的内容；</p>
			<p>4.7 制造虚假身份以误导、欺骗他人；</p>
			<p>4.8 利用本“软件”批量发表、传送、传播广告信息及垃圾信息；</p>
			<p>4.9 传送或散布以其他方式实现传送含有受到知识产权法律保护的图像、相片、软件或其他资料的文件，作为举例（但不限于此）：包括版权或商标法（或隐私权或公开权），除非用户拥有或控制着相应的权利或已得到所有必要的认可；</p>
			<p>4.10 使用任何包含有通过侵犯商标、版权、专利、商业机密或任何一方的其他专有权利的方式利用本“软件”获得的图像或相片的资料或信息；</p>
			<p>4.11 任何人进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/帐号；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本“软件”系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏本“软件”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称；</p>
			<p>4.12 不得通过修改或伪造软件作品运行中的指令、数据、数据包，增加、删减、变动软件的功能或运行效果，不得将用于上述用途的软件通过信息网络向公众传播或者运营；</p>
			<p>4.13 将本软件及提供的服务用于核设施运行、生命维持或其他会使人类及其财产处于危险之中的重大设备。用户明白本软件及提供的服务并非为以上目的而设计，如果因为软件和服务的原因导致以上操作失败而带来的人员伤亡、严重的财产损失和环境破坏，将不承担任何责任； </p>
			<p>4.14 禁止用户制作、发布、传播用于窃取帐号及他人专属信息、财产、保密信息的软件；</p>
			<p>4.15 在未经公司书面明确授权前提下，出售、出租、出借、散布、转移或转授权软件和服务或相关的链接或从使用软件和服务或软件和服务的条款中获利，无论以上使用是否为直接经济或金钱收益；</p>
			<p>4.16 其他以任何不合法的方式、为任何不合法的目的、或以任何与本协议不一致的方式使用本软件和和提供的其他服务；</p>
			<p>4.17 用户若违反上述规定，有权采取终止、完全或部分中止、限制用户帐号的使用功能。</p>
			<p>4.18 使用本“软件”必须遵守国家有关法律和政策等，维护国家利益，保护国家安全，并遵守本《协议》。对于用户违法或违反本《协议》的使用而引起的一切责任，由用户负全部责任，一概与及合作单位无关；导致及合作单位损失的，及合作单位有权要求用户赔偿，并有权保留相关记录。而且，对于用户违法或违反本《协议》以及违反了利用本软件和帐号访问的或合作单位的其他服务规定的相关服务条款，有权视用户的行为性质，在不事先通知用户的情况下，采取包括但不限于中断使用许可、停止提供服务、限制使用、回收用户帐号、法律追究等措施。对利用帐号进行违法活动、骚扰、欺骗其他用户等行为，有权回收其帐号。由此带来的包括并不限于用户通信中断、用户资料、邮件和游戏道具丢失等损失由用户自行承担。 </p>
			<p>4.19 本“软件”同大多数互联网软件一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本“软件”的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。</p>
			<p>4.20 非经或授权开发并正式发布的其它任何由本“软件”衍生的软件均属非法，下载、安装、使用此类软件，将可能导致不可预知的风险，由此产生的一切法律责任与纠纷一概与无关。用户不得轻易下载、安装、使用此类软件，否则，有权在不事先通知用户的情况下单方面终止用户帐号的使用资格。用户只能通过提供的本“软件”及其他合法通道登录使用。用户不得通过其他未经授权开发的包括但不限于非法兼容型软件、程序或者其他非明示许可的方式，登录使用帐号，否则，有权在不事先通知用户的情况下单方面终止用户的帐号的使用资格。</p>
			<p>4.21 用户同意个人隐私信息是指那些能够对用户进行个人辨识或涉及个人通信的信息，<br>包括下列信息：用户的姓名，身份证号，手机号码，IP地址，电子邮件地址信息。而非个人隐私信息是指用户对本软件的操作状态以及使用习惯等一些明确且客观反映在服务器端的基本记录信息和其他一切个人隐私信息范围外的普通信息。尊重用户个人隐私信息的私有性是的一贯制度，将会采取合理的措施保护用户的个人隐私信息，除法律或有法律赋予权限的政府部门要求或用户同意等原因外，未经用户同意不向除合作单位以外的第三方公开、透露用户个人隐私信息。但是用户在注册时选择或同意，或用户与及合作单位之间就用户个人隐私信息公开或使用另有约定的除外，同时用户应自行承担因此可能产生的任何风险，对此不予负责。同时为了运营和改善的技术和服务，我们将可能会自己收集使用或向第三方提供用户的非个人隐私信息，这将有助于向用户提供更好的用户体验和提高我们的服务质量。</p>
			<p>4.22 一般而言，公司基于下列原因需要使用到用户的信息资源：<br>（1）执行软件验证服务； <br>（2）执行软件升级服务； <br>（3）提高用户的使用安全性并提供客户支持；<br>（4）因用户使用特定功能如远程控制等或因用户要求或合作单位提供特定服务时，或合作单位则需要把用户的信息提供给与此相关联的第三方；<br>（5）将各种非个人隐私数据用于商业目的，包括但不限于向第三方提供增值服务、广告、定位广告、营销、联合注册其它服务、促销或其它任何活动（统称为商业活动）；<br>（6）执行的《隐私保护声明》，用户可访问网站查阅该声明；<br>（7）其他有利于用户和利益的。</p>
			<p>4.23 本“软件”需要用户共同享用、维护其所提供的利益，用户在此确认同意本“软件”在必要时使用您计算机的处理器和带宽等资源，用作容许其它本“软件”使用者与您通讯联络、分享本“软件”及服务的有限目的。此项同意可能会影响用户的使用感受和带来不可预知的风险。您应认真考虑并做出选择，承担风险。</p>
			<p>4.24 用户同意本“软件”将会尽其合理努力以保护您的计算机资源及计算机通讯的隐私性和完整性，但是，您承认和同意不能就此事提供任何保证。</p>
			<p>4.25 “用户同意”的方式有：<br>（1）通过点击“用户同意”或者其他方式接受本《协议》及发布的其他服务条款；<br>（2）用户通过电子邮件、电话、传真、即时通信等方式所作的口头或书面表示；<br>（3）本《协议》或其他服务声明中有“默认同意”条款，用户对此无异议的；<br>（4）其他与用户均认可的方式。</p>
			<p>4.26 保留在任何时候根据适用法律、法规、法律程序或政府要求的需要而披露任何信息，或由自主决定全部或部分地编辑、拒绝张贴或删除任何信息或资料的权利。</p>
			<p>4.27 本“软件”的替换、修改和升级：保留在任何时候通过为您提供本“软件”替换、修改、升级版本的权利以及为替换、修改或升级收取费用的权利。本“软件”为用户默认开通“升级提示”功能，视用户使用的“软件”版本差异，提供给用户自行选择是否需要开通此功能。软件新版本发布后，不保证旧版本软件的继续可用。保留因业务发展需要，单方面对软件的部分功能效果进行改变或进行限制，用户需承担此风险。</p>
			<p>4.28 和/或合作单位将根据市场与技术的发展，向用户提供与本“软件”相关的各种互联网以及移动通信增值服务，包括免费和收费的增值服务。和/或合作单位保留对相关增值服务收取费用及改变收费标准、方式的权利；如相关服务由免费变更为收费服务，和/或合作单位将以适当的形式通知，用户可自主选择接受或拒绝收费服务，并保证在使用收费服务时，将按照和/或合作单位相关收费规定支付费用，如拒付或拖欠费用，和/或合作单位有权停止服务，并依法追偿损失及赔偿。</p>
			<p>4.29 公司有权在服务中或经过服务投放各种广告和宣传信息，该广告可能以系统消息或弹出窗口等形式出现。</p>
			<p>4.30 本“软件”可能会使用第三方的软件或技术，该等使用均是获得了合法授权的。如因本“软件”使用的第三方软件或技术引发的任何纠纷，由该第三方负责解决，不承担任何责任。不对该第三方软件或技术提供客服支持，若用户需要获取支持，请与该第三方联系。</p>
			<h2 class="f-bold">五.法律责任与免责</h2>
			<p>5.1 利用的许可<br></p>
			<p>5.1.1 许可利用您的计算机：为了得到本“软件”所提供的利益，用户在此许可利用用户计算机的处理器和宽带，用作容许其它软件使用者与用户通讯联络的有限目的。</p>
			<p>5.1.2 保护用户的计算机（资源）：用户认可软件将会尽其商业上的合理努力以保护用户的计算机资源及计算机通讯的隐私性和完整性，但是，用户承认和同意不能就此事提供任何保证。</p>
			<p>5.1.3 本软件为网络交互型软件，可以根据用户软件使用状态和行为，为了改善软件服务和体验、完善业务内容，自己或与合作方合作开发新的服务或者调整软件功能。</p>
			<p>5.2 特别提请用户注意：为了保障公司业务发展和调整的自主权，拥有随时自行修改或中断软件授权而不需通知用户的权利，如有必要，修改或中断会以通告形式公布于网站重要页面上。</p>
			<p>5.3 用户违反本《协议》或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户同意赔偿与合作公司、关联公司，并使之免受损害。对此，有权视用户的行为性质，在不事先通知用户的情况下，采取本《协议》第4.18条所述的措施及其他相应的措施。</p>
			<p>5.4 使用本“软件”由用户自己承担风险，及合作单位对本“软件”不作任何类型的担保，不论是明示的、默示的或法令的保证和条件，包括但不限于本“软件”的适销性、适用性、无病毒、无疏忽或无技术瑕疵问题、所有权和无侵权的明示或默示担保和条件，对在任何情况下因使用或不能使用本软件所产生的直接、间接、偶然、特殊及后续的损害及风险，及合作单位不承担任何责任。</p>
			<p>5.5 使用本“软件”涉及到互联网服务，可能会受到各个环节不稳定因素的影响，存在因不可抗力、计算机病毒、黑客攻击、系统不稳定、用户所在位置、用户关机，非法内容信息、骚扰信息屏蔽以及其他任何网络、技术、通信线路、信息安全管理措施等原因造成的服务中断、受阻等不能满足用户要求的风险，用户须明白并自行承担以上风险，用户因此不能发送或接收阅读消息，或接、发错消息，及合作单位不承担任何责任。</p>
			<p>5.6 使用本“软件”可能存在来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息的风险，用户须明白并自行承担以上风险，及合作单位不对任何有关信息真实性、适用性、合法性承担责任。</p>
			<p>5.7 用户因第三方如电信部门的通讯线路故障、技术问题、网络、电脑故障、系统不稳定性及其他各种不可抗力原因而遭受的一切损失，及合作单位不承担责任。</p>
			<p>5.8 因技术故障等不可抗事件影响到服务的正常运行的，及合作单位承诺在第一时间内与相关单位配合，及时处理进行修复，但用户因此而遭受的一切损失，及合作单位不承担责任。</p>
			<p>5.9 用户之间通过本“软件”与其他用户交互，因受误导或欺骗而导致或可能导致的任何心理、生理上的伤害以及经济上的损失，由过错方依法承担所有责任，一概与及合作单位无关。</p>
			<p>5.10 深圳饭碗农业物联网科技有限公司保留所有权利和解释权利</p>
		</div>
	</div>

</main>
<footer class="footer">
	<div class="w-fixed">
		<div class="left">
			<div class="logo"></div>
			<p class="slogan">专致于打造好用的溯源系统</p>
			<div class="content">
				<p>客服时间：(8:30-19:00)</p>
				<p>商务合作：451833096@qq.com</p>
			</div>
		</div>
		<div class="nav">
            <div class="nav-item">
                <h1 class="title">产品功能</h1>
                <div class="link">
                    <a class="link-item" (click)="liveFunction()">直播</a>
                    <a class="link-item" (click)="materialManageComponent()">物料</a>
                    <a class="link-item" (click)="traceability()">溯源</a>
                </div>
            </div>
            <div class="nav-item">
                <h1 class="title">应用方案</h1>
                <div class="link">
                    <a class="link-item" (click)="vegetables()">蔬菜</a>
					<a class="link-item" (click)="fruitTree()">果树</a>
					<a class="link-item" (click)="breed()">养殖</a>
                </div>
            </div>
            <div class="nav-item">
                <h1 class="title">资源</h1>
                <div class="link">
                    <a class="link-item" (click)="helpCenter()">帮助中心</a>
                </div>
            </div>
		</div>
	</div>
	<app-copy-right></app-copy-right>
</footer>
<div class="fixed-btn"><a (click)="customerService()" class="btn-kf" target="_blank">客服</a><a href="javascript:void(0)" class="btn-zd">置顶</a></div>
<!-- 注册弹窗s -->
<div id="dialog-reg" class="dialog dialog-login hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="login-title">注册账号</h1>
	<h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
	<div class="container">
		<div class="form">
			<div class="form-wrap">
				<p class="form-title">手机号注册</p>
				<div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
				<div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)" class="btn-text">获取验证码</a></div>
				<div class="form-item"><input type="password" placeholder="密码（8-20位数字字母组合）"></div>
				<a href="javascript:void(0)" class="btn-reg dialog-close">立即注册</a>
			</div>
			<div class="agreement">
				<div class="check-btn"><span><input name="" type="checkbox" value=""></span>已阅读并同意<a (click)="customerService()"
					class="btn-text">《饭碗用户协议》</a>及<a href="javascript:void(0)" (click)="privacyAgreement()" class="btn-text">《隐私协议》</a></div>
			  </div>

		</div>
		<div class="ewm">
			<p class="ewm-title">微信快捷登录</p>
			<p class="ewm-img"><img src="assets/images/ewm.png" alt=""></p>
			<p class="ewm-prompt">无需验证，快捷登录</p>
		</div>
	</div>
	<div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text" onClick="changeDiaolg('#dialog-reg','#dialog-login')">直接登录</a></div>
</div>
<!-- 注册弹窗e -->
<!-- 登录弹窗s -->
<div id="dialog-login" class="dialog dialog-login hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="login-title">登录饭碗账号</h1>
	<h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
	<div class="container">
		<div class="form">
			<div class="form-wrap">
				<p class="form-title">手机号登录</p>
				<div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
				<div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)" class="btn-text">获取验证码</a></div>
				<div class="form-check">
					<div class="check-box"><div class="check-btn"><span><input name="" type="checkbox" value=""></span>自动登录</div></div>
					<a href="javascript:void(0)" (click)="forgetPassword()" class="btn-forget-pwd">忘记密码！</a>
				</div>
				<a href="javascript:void(0)" class="btn-reg dialog-close">登录</a>
			</div>
		</div>
		<div class="ewm">
			<p class="ewm-title">微信快捷登录</p>
			<p class="ewm-img"><img src="assets/images/ewm.png" alt=""></p>
			<p class="ewm-prompt">无需验证，快捷登录</p>
		</div>
	</div>
	<div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text" onClick="changeDiaolg('#dialog-login','#dialog-reg')">立即注册</a></div>
</div>
<!-- 登录弹窗e -->
<div id="dialog-bg" class="dialog-bg hidden"></div>
