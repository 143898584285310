<header class="header">
	<ul>
		<div class="logo"></div>
		<div class="menu">
			<li class="menu-item"><a class="menu-link"   href="javascript:void(0)"  (click)="index()">首页</a></li>
			<li class="menu-item drop-down-menu">
				<a class="menu-link" href="javascript:void(0)">产品功能</a>
				<div class="drop-down-menu-wrap">
					<div class="drop-down-menu-box">
						<div class="sub-menu">
							<ul>
								<li><a href="javascript:void(0)" (click)="materialManageComponent()"><p class="name">物料管理</p><p>扫码即可出入库</p></a></li>
								<li><a href="javascript:void(0)" (click)="liveFunction()"><p class="name">直播功能</p><p>可对接各大直播平台</p></a></li>
								<li><a href="javascript:void(0)" (click)="dataAnalysis()"><p class="name">数据分析</p><p>可导出生产数据报表</p></a></li>
								<li><a href="javascript:void(0)" (click)="waterFertilizer()"><p class="name">水肥一体</p><p>通过系统控制水肥系统</p></a></li>
								<li><a href="javascript:void(0)" (click)="soilMoisture()"><p class="name">土壤墒情</p><p>监控土壤墒情</p></a></li>
								<li><a href="javascript:void(0)" (click)="climateMonitoring()"><p class="name">气候监控</p><p>随时随地都能查看</p></a></li>
								<li><a href="javascript:void(0)" (click)="coldtracking()"><p class="name">冷链跟踪</p><p>可查看冷链车相关情况</p></a></li>
								<li><a href="javascript:void(0)" (click)="traceability()"><p class="name">溯源功能</p><p>可生成溯源二维码</p></a></li>
							</ul>
						</div>
					</div>
				</div>
			</li>
			<li class="menu-item drop-down-menu">
				<a class="menu-link" href="javascript:void(0)">应用方案</a>
				<div class="drop-down-menu-wrap">
					<div class="drop-down-menu-box">
						<div class="sub-menu">
                            <ul>
								<li><a href="javascript:void(0)" (click)="vegetables()">
									<p class="name">蔬菜</p>
									<p>从容生产</p>
								</a></li>
							<li><a  href="javascript:void(0)" (click)="fruitTree()">
									<p class="name">果树</p>
									<p>高品质果树</p>
								</a></li>
							<li><a href="javascript:void(0)" (click)="breed()">
									<p class="name">养殖</p>
									<p>智慧养殖</p>
								</a></li>
							</ul>
						</div>
					</div>
				</div>
			</li>
			<li class="menu-item"><a class="menu-link"  href="javascript:void(0)" (click)="hardwareService()">硬件服务</a></li>
			<!-- <li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="price()">价格</a></li> -->
		</div>
		<div class="right">
			<a href="javascript:void(0)"  (click)="search()" class="btn-search">搜索</a>
			<a href="javascript:void(0)" class="btn-register" onClick="openDiaolg('#dialog-reg','.dialog-bg')">免费注册</a>
			<!-- 我的s -->
			<div class="mine">
				<div class="avatar"><i class="icon-avatar"></i><i class="icon-arrow"></i></div>
				<div class="mine-popup">
					<div class="mine-popup-wrap">
						<div class="user-info">
							<p class="tel">13352928035</p>
							<p class="version">免费版<span class="btn-upgrade">升级</span></p>
						</div>
						<div class="nav">
                            <a href="javascript:void(0)" (click)="myOrder()" class="nav-item"><i class="icon icon-dd"></i>我的订单</a>
                            <a  href="javascript:void(0)" (click)="myAddress()"  class="nav-item"><i class="icon icon-dz"></i>我的地址</a>
                            <a href="javascript:void(0)"   (click)="accountInfo()" class="nav-item"><i class="icon icon-zh"></i>账户信息</a>
                            <a href="javascript:void(0)" (click)="index()" class="nav-item" id="aqtc"><i class="icon icon-tc"></i>安全退出</a>
						</div>
					</div>
				</div>
			</div>
			<!-- 我的e -->
		</div>
	</ul>
</header>
<main class="zhmm bg-light-green">
	<div class="zhmm-wrap">
		<h1 class="zhmm-title">找回密码</h1>
		<div class="form">
			<div class="form-item"><input type="text" placeholder="输入手机号码"></div>
			<div class="form-item form-item-code"><input type="text" placeholder="输入验证码"><a href="javascript:void(0)" class="get-code"><img src="assets/images/icon_code.jpg" alt=""></a></div>
			<a href="javascript:void(0)" class="btn-get-pwd">获取密码</a>
		</div>
		<p class="prompt">注意查收饭碗科技手机短信信息</p>
		<p class="change-tel">更换了手机号?  <a   (click)="customerService()" target="_blank" class="btn-blue-text">联系客服</a></p>
	</div>
</main>
<footer class="zhmm-foot bg-light-green">
	<div class="link">
        <a class="link-item"  href="javascript:void(0)" (click)="helpCenter()">帮助中心</a>
        <a class="link-item"  href="javascript:void(0)" (click)="userAgreement()">用户协议</a>
        <a class="link-item"   href="javascript:void(0)"  (click)="about()">关于我们</a>
		<!-- <a class="link-item" href="gywm.html">关于我们</a> -->
	</div>
	<app-copy-right></app-copy-right>
</footer>
<!-- <div class="fixed-btn"><a href="kf.html" class="btn-kf" target="_blank">客服</a><a href="javascript:void(0)" class="btn-zd">置顶</a></div> -->
<!-- 注册弹窗s -->
<div id="dialog-reg" class="dialog dialog-login hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="login-title">注册账号</h1>
	<h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
	<div class="container">
		<div class="form">
			<div class="form-wrap">
				<p class="form-title">手机号注册</p>
				<div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
				<div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)" class="btn-text">获取验证码</a></div>
				<div class="form-item"><input type="password" placeholder="密码（8-20位数字字母组合）"></div>
				<a href="javascript:void(0)" class="btn-reg dialog-close">立即注册</a>
			</div>
            <div class="agreement">
				<div class="check-btn"><span><input name="" type="checkbox" value=""></span>已阅读并同意<a (click)="customerService()"
					class="btn-text">《饭碗用户协议》</a>及<a href="javascript:void(0)" (click)="privacyAgreement()" class="btn-text">《隐私协议》</a></div>
			  </div>
		</div>
		<div class="ewm">
			<p class="ewm-title">微信快捷登录</p>
			<p class="ewm-img"><img src="assets/images/ewm.png" alt=""></p>
			<p class="ewm-prompt">无需验证，快捷登录</p>
		</div>
	</div>
	<div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text" onClick="changeDiaolg('#dialog-reg','#dialog-login')">直接登录</a></div>
</div>
<!-- 注册弹窗e -->
<!-- 登录弹窗s -->
<div id="dialog-login" class="dialog dialog-login hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="login-title">登录饭碗账号</h1>
	<h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
	<div class="container">
		<div class="form">
			<div class="form-wrap">
				<p class="form-title">手机号登录</p>
				<div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
				<div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)" class="btn-text">获取验证码</a></div>
				<div class="form-check">
					<div class="check-box"><div class="check-btn"><span><input name="" type="checkbox" value=""></span>自动登录</div></div>
					<a href="javascript:void(0)" (click)="forgetPassword()" class="btn-forget-pwd">忘记密码！</a>
				</div>
				<a href="javascript:void(0)" class="btn-reg dialog-close">登录</a>
			</div>
		</div>
		<div class="ewm">
			<p class="ewm-title">微信快捷登录</p>
			<p class="ewm-img"><img src="assets/images/ewm.png" alt=""></p>
			<p class="ewm-prompt">无需验证，快捷登录</p>
		</div>
	</div>
	<div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text" onClick="changeDiaolg('#dialog-login','#dialog-reg')">立即注册</a></div>
</div>
<!-- 登录弹窗e -->
<div id="dialog-bg" class="dialog-bg hidden"></div>
