import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fruit-tree',
  templateUrl: './fruit-tree.component.html',
  styleUrls: ['./fruit-tree.component.css']
})
export class FruitTreeComponent implements OnInit {
  constructor(public router:Router) { }

 
  ngOnInit() {


    this.init()

  }
  init(){
    
    if ($(".btn-zd").length) {
      $(document).on("scroll", function () {
        var c = $(document).scrollTop();
        if (c > 30) {
          $(".btn-zd").css("display", "block");
        } else {
          $(".btn-zd").css("display", "none");
        }
      })
      $(".btn-zd").click(function () {
        $("html,body").animate({ scrollTop: 0 }, "slow");
      })
    }

    if ($("#Quick-navigation").length) {
      var a = $('#Quick-navigation'),
        b = a.offset();
      $(document).on('scroll', function () {
        var c = $(document).scrollTop();
        if (b.top <= c) {
          a.css({ 'position': 'fixed', 'top': '98px' })
        } else {
          a.css({ 'position': 'relative', 'top': '0' })
        }
      })
    }

    if ($("#xzbb .list .list-item").length) {
      $("#xzbb .list .list-item").click(function () {
        $(this).addClass("selected").siblings().removeClass("selected");
      })
    }

    if ($("#scxz .list .list-item").length) {
      $("#scxz .list .list-item").click(function () {
        $(this).addClass("selected").siblings().removeClass("selected");
      })
    }

    if ($("#zffs .list .list-item").length) {
      $("#zffs .list .list-item").click(function () {
        $(this).addClass("selected").siblings().removeClass("selected");
      })
    }

    if ($(".dialog-close").length) {
      $(".dialog-bg,.dialog-close").click(function () {
        $(".dialog").fadeOut("fast");
        $(".dialog-bg").fadeOut("fast");
      })
    }
  }
  index(){
    this.router.navigate(['/index'])
  }
  accountInfo(){
    this.router.navigate(['/accountInfo'])
  }

  myOrder(){
    this.router.navigate(['/myOrder'])
  }
  
  myAddress(){
    this.router.navigate(['/myAddress'])
  }
  materialManageComponent(){
    this.router.navigate(['/materialManageComponent'])
  }

  liveFunction(){
    this.router.navigate(['/liveFunction'])
  }
  price(){
    this.router.navigate(['/price'])
  }
  hardwareService(){
    this.router.navigate(['/hardwareService'])
  }
 about(){
  this.router.navigate(['/about'])
 }
// 数据分析
 dataAnalysis(){
  this.router.navigate(['/dataAnalysis'])
 }

 waterFertilizer(){
  this.router.navigate(['/waterFertilizer'])
 }
 soilMoisture(){
  this.router.navigate(['/soilMoisture'])
 }
 climateMonitoring(){
  this.router.navigate(['/climateMonitoring'])
}
coldtracking(){
  this.router.navigate(['/coldtracking'])
}
  traceability(){
    this.router.navigate(['/traceability'])
  }
  vegetables(){
    this.router.navigate(['/vegetables'])
  }

  fruitTree(){
    this.router.navigate(['/fruitTree'])
  }
  breed(){
    this.router.navigate(['/breed'])
  }
  customerService(){
    this.router.navigate(['/customerService'])
  }
  helpCenter(){
    this.router.navigate(['/helpCenter'])
  }
  userAgreement(){
    this.router.navigate(['/userAgreement'])
  }

  search(){
    this.router.navigate(['/search'])
  }
  privacyAgreement(){
    this.router.navigate(['/privacyAgreement'])
  }
  forgetPassword(){
    this.router.navigate(['/forgetPassword'])
  }


    // //弹窗 
    openDiaolg(dialog, dialog_bg) {
      $(dialog_bg).fadeIn("fast");
      $(dialog).fadeIn("fast");
    }
  
    changeDiaolg(hide_dialog, show_dialog) {
      $(hide_dialog).hide();
      $(show_dialog).show();
    }

}
