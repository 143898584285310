<header class="header">
  <ul>
    <div class="logo"></div>
    <div class="menu">
      <li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="index()">首页</a></li>
      <li class="menu-item drop-down-menu">
        <a class="menu-link" href="javascript:void(0)">产品功能</a>
        <div class="drop-down-menu-wrap">
          <div class="drop-down-menu-box">
            <div class="sub-menu">
              <ul>
                <li><a href="javascript:void(0)" (click)="materialManageComponent()">
                    <p class="name">物料管理</p>
                    <p>扫码即可出入库</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="liveFunction()">
                    <p class="name">直播功能</p>
                    <p>可对接各大直播平台</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="dataAnalysis()">
                    <p class="name">数据分析</p>
                    <p>可导出生产数据报表</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="waterFertilizer()">
                    <p class="name">水肥一体</p>
                    <p>通过系统控制水肥系统</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="soilMoisture()">
                    <p class="name">土壤墒情</p>
                    <p>监控土壤墒情</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="climateMonitoring()">
                    <p class="name">气候监控</p>
                    <p>随时随地都能查看</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="coldtracking()">
                    <p class="name">冷链跟踪</p>
                    <p>可查看冷链车相关情况</p>
                  </a></li>
                <!-- <li><a href="dpjk.html">
                    <p class="name">大屏监控功能</p>
                    <p>通过大屏即知生产情况</p>
                  </a></li> -->
                <li><a href="javascript:void(0)" (click)="traceability()">
                    <p class="name">溯源功能</p>
                    <p>可生成溯源二维码</p>
                  </a></li>
              </ul>
            </div>
          </div>
        </div>
      </li>
      <li class="menu-item drop-down-menu">
        <a class="menu-link" href="javascript:void(0)">应用方案</a>
        <div class="drop-down-menu-wrap">
          <div class="drop-down-menu-box">
            <div class="sub-menu">
              <ul>
                <li><a href="javascript:void(0)" (click)="vegetables()">
                    <p class="name">蔬菜</p>
                    <p>从容生产</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="fruitTree()">
                    <p class="name">果树</p>
                    <p>高品质果树</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="breed()">
                    <p class="name">养殖</p>
                    <p>智慧养殖</p>
                  </a></li>
              </ul>
            </div>
          </div>
        </div>
      </li>
      <li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="hardwareService()">硬件服务</a></li>
      <!-- <li class="menu-item selected"><a class="menu-link" href="javascript:void(0)" (click)="price()">价格</a></li> -->
    </div>
    <div class="right">
      <a href="javascript:void(0)" (click)="search()" class="btn-search">搜索</a>
      <a href="javascript:void(0)" class="btn-register" (click)="openDiaolg('#dialog-reg','.dialog-bg')">免费注册</a>
    </div>
  </ul>
</header>
<main class="jg">
  <div class="w-fixed">
    <h1 class="header-title">选择适合你的溯源系统</h1>
    <h2 class="header-sub-title">如果你有业务扩增，支持补差价升级</h2>
    <section class="bbxx">
      <div class="bbxx-item">
        <p class="name">简化版</p>
        <p class="content">适合与政府对接，<br />有溯源需求的</p>
        <p class="price">￥<span class="f-big">0</span>/年</p>
      </div>
      <div class="bbxx-item">
        <p class="name">初级版</p>
        <p class="content">对物料有严格要求<br />有溯源需求的</p>
        <p class="price">￥<span class="f-big">2580</span>/年</p>
      </div>
      <div class="bbxx-item">
        <p class="name">高级版</p>
        <p class="content">对物料有严格要求，<br />对生产有严格要求</p>
        <p class="price">￥<span class="f-big">5680</span>/年</p>
      </div>
      <div class="bbxx-item">
        <p class="name">旗舰版</p>
        <p class="content">对生产/加工/物流<br />有严格要求</p>
        <p class="price">￥<span class="f-big">11800</span>/年</p>
      </div>
      <div class="bbxx-item">
        <p class="name">行业豪华版</p>
        <p class="content">对生产/加工/物流/品牌<br />宣传有严格要求</p>
        <p class="price f-orange">￥<span class="f-big">18680</span>/年</p>
      </div>
    </section>
    <section class="bbjs">
      <div class="list">
        <div class="list-item">
          <p>简化版溯源系统</p>
          <p>简化版小程序</p>
        </div>
        <div class="list-item">
          <p>溯源系统</p>
          <p>+物料系统</p>
          <p>+扫描二维码进出库</p>
          <p>+可导出物料进出表</p>
          <p>+小程序</p>
        </div>
        <div class="list-item">
          <p>溯源系统</p>
          <p>+物料系统</p>
          <p>+生产管理系统</p>
          <p>+可导出产出报表</p>
          <p>+可导出批次所用物资</p>
          <p>+小程序</p>
        </div>
        <div class="list-item">
          <p>溯源系统</p>
          <p>+物料系统</p>
          <p>+生产管理系统</p>
          <p>+物料监控</p>
          <p>+直播功能</p>
          <p>+小程序</p>
          <p>+App</p>
        </div>
        <div class="list-item">
          <p>溯源系统</p>
          <p>+物料系统</p>
          <p>+生产管理系统</p>
          <p>+物流系统</p>
          <p>+直播功能</p>
          <p>+大屏展示功能</p>
          <p>+水肥一体控制</p>
          <p>+气候监测功能</p>
          <p>+病虫害监测功能</p>
          <p>+小程序</p>
          <p>+专属App</p>
        </div>

      </div>
      <div class="btn">
        <div class="btn-item">
          <a href="http://simpleagriculture.fine1.cn/" class="bn-orange">立即使用</a>
        </div>
        <div class="btn-item">
          <!-- <a href="javascript:void(0)" class="bn-orange" (click)="buyBow()">立即购买</a> -->
        </div>
        <div class="btn-item">
          <!-- <a href="javascript:void(0)" class="bn-orange" (click)="buyBow()">立即购买</a> -->
        </div>
        <div class="btn-item">
          <!-- <a href="javascript:void(0)" class="bn-orange" (click)="buyBow()">立即购买</a> -->
          <p><a href="javascript:void(0)" class="btn-text-blue" (click)="customerService()">了解详情</a></p>
        </div>
        <div class="btn-item">
          <!-- <a href="javascript:void(0)" class="bn-orange" (click)="buyBow()">立即购买</a> -->
          <p><a href="javascript:void(0)" class="btn-text-blue" (click)="customerService()">了解详情</a></p>
        </div>
      </div>

    </section>
    <section class="csdb table">
      <div class="title">各版本参数对比</div>
      <div class="thead">
        <p class="td">参数对比</p>
        <p class="td">简化版<br />￥560/年</p>
        <p class="td">初级版<br />￥1580/年</p>
        <p class="td">高级版<br />￥3680/年</p>
        <p class="td">旗舰版<br />￥8800/年</p>
        <p class="td">行业豪华版<br />￥15680/年</p>
      </div>
      <div class="table-title-shadow">基础功能</div>
      <div class="tbody">
        <div class="tr">
          <p class="td">生成溯源码</p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
        <div class="tr">
          <p class="td">物料管理</p>
          <p class="td"><i class="icon-add" (click)="addNumber()"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
        <div class="tr">
          <p class="td">导出物料报表</p>
          <p class="td"></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
        <div class="tr">
          <p class="td">导出产出报表</p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
        <div class="tr">
          <p class="td">打印采收二维码</p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
        <div class="tr">
          <p class="td">导出所耗物资报表</p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
        <div class="tr">
          <p class="td">物流监控</p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
        <div class="tr">
          <p class="td">直播功能</p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"><i class="icon-add"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
        <div class="tr">
          <p class="td">专属APP</p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
      </div>
      <div class="table-title">账号数量</div>
      <div class="tbody">
        <div class="tr">
          <p class="td">账号数量</p>
          <p class="td"><i class="icon-add"></i><span class="num">1</span><i class="icon-add"></i></p>
          <p class="td"><i class="icon-add"></i><span class="num">1</span><i class="icon-add"></i></p>
          <p class="td"><i class="icon-add"></i><span class="num">1</span><i class="icon-add"></i></p>
          <p class="td"><i class="icon-add"></i><span class="num">2</span><i class="icon-add"></i></p>
          <p class="td"><i class="icon-add"></i><span class="num">3</span><i class="icon-add"></i></p>
        </div>
      </div>
      <div class="table-title">服务器支持</div>
      <div class="tbody">
        <div class="tr">
          <p class="td">每天系统数据备份</p>
          <p class="td"></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
      </div>
      <div class="table-title">售后服务</div>
      <div class="tbody">
        <div class="tr">
          <p class="td">5*8小时在线客服</p>
          <p class="td">工单支持</p>
          <p class="td">工单支持</p>
          <p class="td">工单支持</p>
          <p class="td">专属客服</p>
          <p class="td">专属客服</p>
        </div>
        <div class="tr">
          <p class="td">技术支持</p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
        <div class="tr">
          <p class="td">远程培训服务</p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"><i class="icon-hook"></i></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
        <div class="tr">
          <p class="td">落地应用支持</p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"></p>
          <p class="td"><i class="icon-hook"></i></p>
        </div>
        <div class="tr btn">
          <p class="td"></p>
          <p class="td"><a href="http://simpleagriculture.fine1.cn/" class="btn-item">立即使用</a></p>
          <!-- <p class="td"><a (click)="buyBow()" href="javascript:void(0)" class="btn-item">立即购买</a></p>
          <p class="td"><a (click)="buyBow()" href="javascript:void(0)" class="btn-item">立即购买</a></p>
          <p class="td"><a (click)="buyBow()" href="javascript:void(0)" class="btn-item">立即购买</a></p>
          <p class="td"><a (click)="buyBow()" href="javascript:void(0)" class="btn-item">立即购买</a></p> -->
        </div>
      </div>
    </section>

  </div>
</main>
<footer class="footer">
  <div class="w-fixed">
    <div class="left">
      <div class="logo"></div>
      <p class="slogan">专致于打造好用的溯源系统</p>
      <div class="content">
        <p>客服时间：(8:30-19:00)</p>
        <p>商务合作：451833096@qq.com</p>
      </div>
    </div>
    <div class="nav">
      <div class="nav-item">
        <h1 class="title">产品功能</h1>
        <div class="link">
          <a class="link-item" (click)="liveFunction()">直播</a>
          <a class="link-item" (click)="materialManageComponent()">物料</a>
          <a class="link-item" (click)="traceability()">溯源</a>

        </div>
      </div>
      <div class="nav-item">
        <h1 class="title">应用方案</h1>
        <div class="link">
          <a class="link-item" (click)="vegetables()">蔬菜</a>
          <a class="link-item" (click)="fruitTree()">果树</a>
          <a class="link-item" (click)="breed()">养殖</a>
        </div>
      </div>
      <div class="nav-item">
        <h1 class="title">资源</h1>
        <div class="link">
          <!-- <a class="link-item" href="hyal.html">行业案列</a> -->
          <a class="link-item" (click)="helpCenter()">帮助中心</a>
        </div>
      </div>
      <div class="nav-item">
        <h1 class="title">公司</h1>
        <div class="link">
          <a class="link-item" (click)="userAgreement()">用户协议</a>
          <!-- <a class="link-item">关于我们</a> -->
          <a class="link-item" (click)="about()">关于我们</a>
        </div>
      </div>
    </div>
  </div>
  <app-copy-right></app-copy-right>
</footer>
<div class="fixed-btn"><a (click)="customerService()" class="btn-kf" target="_blank">客服</a><a href="javascript:void(0)"
    class="btn-zd">置顶</a></div>
<!-- 注册弹窗s -->
<div id="dialog-reg" class="dialog dialog-login hidden">
  <a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
  <h1 class="login-title">注册账号</h1>
  <h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
  <div class="container">
    <div class="form">
      <div class="form-wrap">
        <p class="form-title">手机号注册</p>
        <div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
        <div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)"
            class="btn-text">获取验证码</a></div>
        <div class="form-item"><input type="password" placeholder="密码（8-20位数字字母组合）"></div>
        <a href="javascript:void(0)" class="btn-reg dialog-close">立即注册</a>
      </div>
      <div class="agreement">
        <div class="check-btn"><span><input name="" type="checkbox" value=""></span>已阅读并同意<a (click)="customerService()"
            class="btn-text">《饭碗用户协议》</a>及<a href="javascript:void(0)" (click)="privacyAgreement()"
            class="btn-text">《隐私协议》</a></div>
      </div>
    </div>
    <div class="ewm">
      <p class="ewm-title">微信快捷登录</p>
      <p class="ewm-img"><img src="assets/images/ewm.png" alt=""></p>
      <p class="ewm-prompt">无需验证，快捷登录</p>
    </div>
  </div>
  <div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text"
      onClick="changeDiaolg('#dialog-reg','#dialog-login')">直接登录</a></div>
</div>
<!-- 注册弹窗e -->
<!-- 登录弹窗s -->
<div id="dialog-login" class="dialog dialog-login hidden">
  <a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
  <h1 class="login-title">登录饭碗账号</h1>
  <h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
  <div class="container">
    <div class="form">
      <div class="form-wrap">
        <p class="form-title">手机号登录</p>
        <div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
        <div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)"
            class="btn-text">获取验证码</a></div>
        <div class="form-check">
          <div class="check-box">
            <div class="check-btn"><span><input name="" type="checkbox" value=""></span>自动登录</div>
          </div>
          <a href="javascript:void(0)" (click)="forgetPassword()" class="btn-forget-pwd">忘记密码！</a>
        </div>
        <a href="javascript:void(0)" class="btn-reg dialog-close">登录</a>
      </div>
    </div>
    <div class="ewm">
      <p class="ewm-title">微信快捷登录</p>
      <p class="ewm-img"><img src="assets/images/ewm.png" alt=""></p>
      <p class="ewm-prompt">无需验证，快捷登录</p>
    </div>
  </div>
  <div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text"
      onClick="changeDiaolg('#dialog-login','#dialog-reg')">立即注册</a></div>
</div>
<!-- 登录弹窗e -->
<div id="dialog-bg" class="dialog-bg hidden"></div>
