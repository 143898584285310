<main class="kf" style="padding-top: 0;background-color: #f5faf7;">
    <div class="kf-header">饭碗科技</div>
    <div class="container">
        <div class="left">
            <p class="logo"></p>
            <p class="time">在线时间：8:30-19:00</p>
            <div class="wx">
                <p class="wx-title">添加微信</p>
                <p class="ewm"><img src="assets/images/ewm_wxs.png" alt=""></p>
                <p class="prompt">扫一扫上面二维码图案，加客服微信</p>
            </div>
        </div>
        <div class="right">
            <div class="msg"></div>
            <div class="msg-textarea"><textarea name="textarea" placeholder="请输入，按enter直接发送消息"></textarea></div>
            <div class="toolbar">
                <div class="btn-group">
                    <a href="javascript:void(0)" title="图片"><img src="assets/images/icon_picture.png" alt=""></a>
                    <a href="javascript:void(0)" title="表情"><img src="assets/images/icon_expression.png" alt=""></a>
                    <a href="javascript:void(0)" title="文件夹"><img src="assets/images/icon_older.png" alt=""></a>
                </div>
                <a href="javascript:void(0)" class="btn-send">发送</a>
            </div>
        </div>
    </div>
</main>