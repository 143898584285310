import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copy-right',
  templateUrl: './copy-right.component.html',
  styleUrls: ['./copy-right.component.css']
})
export class CopyRightComponent implements OnInit {

  icpNo = '粤ICP备17094942号';
  constructor() { }

  ngOnInit(): void {
    const hostName = window.location.hostname;
    if (hostName.includes('iagro.net')) {
      this.icpNo = '粤ICP备17094942号-1';
    } else if (hostName.includes('iagro.club')) {
      this.icpNo = '粤ICP备17094942号-2';
    } else if (hostName.includes('iagro.info')) {
      this.icpNo = '粤ICP备17094942号-3';
    } else if (hostName.includes('fine1.com.cn')) {
      this.icpNo = '粤ICP备17094942号-4';
    } else if (hostName.includes('fine1.cn')) {
      this.icpNo = '粤ICP备17094942号-5';
    } else if (hostName.includes('iagro.xyz')) {
      this.icpNo = '粤ICP备17094942号-6';
    } else if (hostName.includes('fineone.xyz')) {
      this.icpNo = '粤ICP备17094942号-7';
    }


  }

}
