<header class="header">
	<ul>
		<div class="logo"></div>
		<div class="menu">
			<li class="menu-item"><a class="menu-link"  href="javascript:void(0)"  (click)="index()">首页</a></li>
			<li class="menu-item drop-down-menu">
				<a class="menu-link" href="javascript:void(0)">产品功能</a>
				<div class="drop-down-menu-wrap">
					<div class="drop-down-menu-box">
						<div class="sub-menu">
							<ul>
							    <li><a href="javascript:void(0)" (click)="materialManageComponent()">
                                    <p class="name">物料管理</p>
                                    <p>扫码即可出入库</p>
                                </a></li>
                            <li><a href="javascript:void(0)" (click)="liveFunction()">
                                    <p class="name">直播功能</p>
                                    <p>可对接各大直播平台</p>
                                </a></li>
                            <li><a href="javascript:void(0)"  (click)="dataAnalysis()">
                                    <p class="name">数据分析</p>
                                    <p>可导出生产数据报表</p>
                                </a></li>
                            <li><a href="javascript:void(0)" (click)="waterFertilizer()">
                                    <p class="name">水肥一体</p>
                                    <p>通过系统控制水肥系统</p>
                                </a></li>
                            <li><a href="javascript:void(0)" (click)="soilMoisture()">
                                    <p class="name">土壤墒情</p>
                                    <p>监控土壤墒情</p>
                                </a></li>
                            <li><a href="javascript:void(0)" (click)="climateMonitoring()">
                                    <p class="name">气候监控</p>
                                    <p>随时随地都能查看</p>
                                </a></li>
                            <li><a  href="javascript:void(0)" (click)="coldtracking()">
                                    <p class="name">冷链跟踪</p>
                                    <p>可查看冷链车相关情况</p>
                                </a></li>
                            <li ><a href="javascript:void(0)" (click)="traceability()">
                                    <p class="name">溯源功能</p>
                                    <p>可生成溯源二维码</p>
                                </a></li>
							</ul>
						</div>
					</div>
				</div>
			</li>
			<li class="menu-item drop-down-menu">
				<a class="menu-link" href="javascript:void(0)">应用方案</a>
				<div class="drop-down-menu-wrap">
					<div class="drop-down-menu-box">
						<div class="sub-menu">
                            <ul>
                                <li><a href="javascript:void(0)" (click)="vegetables()">
                                    <p class="name">蔬菜</p>
                                    <p>从容生产</p>
                                </a></li>
                            <li class="selected"><a href="javascript:void(0)" (click)="fruitTree()">
                                    <p class="name">果树</p>
                                    <p>高品质果树</p>
                                </a></li>
                            <li><a  href="javascript:void(0)" (click)="breed()" >
                                    <p class="name">养殖</p>
                                    <p>智慧养殖</p>
                                </a></li>
                            </ul>
						</div>
					</div>
				</div>
			</li>
            <li class="menu-item"><a class="menu-link" (click)="hardwareService()">硬件服务</a></li>
            <!-- <li class="menu-item"><a class="menu-link" (click)="price()">价格</a></li> -->
		</div>
		<div class="right">
            <a  href="javascript:void(0)" (click)="search()" class="btn-search">搜索</a>
			<a href="javascript:void(0)" class="btn-register" onClick="openDiaolg('#dialog-reg','.dialog-bg')">免费注册</a>
			<!-- 我的s -->
			<div class="mine">
				<div class="avatar"><i class="icon-avatar"></i><i class="icon-arrow"></i></div>
				<div class="mine-popup">
					<div class="mine-popup-wrap">
						<div class="user-info">
							<p class="tel">13352928035</p>
							<p class="version">免费版<span class="btn-upgrade">升级</span></p>
						</div>
						<div class="nav">
                            <a href="javascript:void(0)" (click)="myOrder()" class="nav-item"><i class="icon icon-dd"></i>我的订单</a>
                            <a  href="javascript:void(0)" (click)="myAddress()"  class="nav-item"><i class="icon icon-dz"></i>我的地址</a>
                            <a href="javascript:void(0)"   (click)="accountInfo()" class="nav-item"><i class="icon icon-zh"></i>账户信息</a>
                            <a href="javascript:void(0)" (click)="index()" class="nav-item" id="aqtc"><i class="icon icon-tc"></i>安全退出</a>
						</div>
					</div>
				</div>
			</div>
			<!-- 我的e -->
		</div>
	</ul>
</header>
<main class="gmbb">
	<div class="w-fixed">
		<h1 class="header-title">硬件购买</h1>
		<div class="container">
			<div class="left">
				<div class="xzbb" id="xzbb">
					<div class="title-bar">硬件名称：<span class="name">土壤温湿度传感器</span></div>
				</div>
				<div class="gmjg">
					<div class="title-bar">购买价格：</div>
					<div class="cell">
						<p class="content"><i class="icon-reduce-disable"></i><span class="num">1</span><i class="icon-add"></i><span class="discount">超过10件8.5折</span></p>
						<p class="price"><span class="f-orange">300</span>元/件</p>
						<p class="total-price">总价300元</p>
					</div>
				</div>
				<div class="ddxx">
					<div class="title-bar">订单信息：</div>
					<div class="cell-box">
						<p class="cell-list">
							<span>初级版 | 1年</span>
							<span>￥1580</span>
						</p>
						<p class="cell-list">
							<span>到期日期</span>
							<span>2022-08-15</span>
						</p>
						<p class="cell-list">
							<span>优惠</span>
							<span class="f-orange">0</span>
						</p>
						<p class="cell-list total-price">
							<span>支付金额</span>
							<span class="f-orange">￥1580</span>
						</p>
					</div>
				</div>
				<div class="dzxx">
					<div class="title-bar">地址信息：</div>
					<div class="cell">
						<p class="content">未添加地址未添加地址未添加地址未添加地址未添未添加地址未添加地址</p>
						<p class="btn"><a href="javascript:void(0)" class="btn-add" (click)="myAddress()">添加<br>地址</a></p>
					</div>
				</div>
				<div class="zffs" id="zffs">
					<div class="title-bar">支付方式：</div>
					<div class="list">
						<p class="list-item selected"><img src="assets/images/icon_alipay.png" alt="">支付宝</p>
						<p class="list-item"><img src="assets/images/icon_wx.png" alt="">微信支付</p>
						<p class="list-item"><img src="assets/images/转账.png" alt="">对公转账</p>
					</div>
				</div>
				<a href="javascript:void(0)" class="btn-to-pay" onClick="openDiaolg('#dialog-user-notice','.dialog-bg')">去付款</a>
			</div>
			<div class="right">
				<div class="title-bar">技术参数</div>
				<div class="body">
					测量参数：土壤容积含水率<br>测量单位：%（m3/m3）<br>测量量程：0~100%<br>测量精度：0~50%(m3/m3) <br>范围内为±2%(m3/m3)；<br>工作温度：-30°Ϲ~70°Ϲ<br>测量参数：土壤温度(°Ϲ)<br>测量范围：-30°Ϲ~70°Ϲ<br>测量精度：±0.15℃<br>25℃时达到最佳精度±0.1℃
				</div>
				<div class="title-bar">功能特点</div>
				<div class="body">
					1、本传感器体积小巧化设计，测量精度高，响应速度快，互换性好<br>2、密封性好，可直接埋入土壤壤中使用，且不受腐蚀。<br>3、实时温湿度监测功功能，可测量不同深度土壤的温温度。 <br>4、土质影响较小，应用地区广泛。<br>5、测量精度高，性能可靠，确保正常工作，响应速度快，数据传输效率高。
				</div>
			</div>
		</div>
	</div>
</main>
<footer class="footer">
	<div class="w-fixed">
		<div class="left">
			<div class="logo"></div>
			<p class="slogan">专致于打造好用的溯源系统</p>
			<div class="content">
				<p>客服时间：(8:30-19:00)</p>
				<p>商务合作：451833096@qq.com</p>
			</div>
		</div>
		<div class="nav">
			<div class="nav-item">
				<h1 class="title">产品功能</h1>
				<div class="link">
                    <a class="link-item" (click)="liveFunction()">直播</a>
                    <a class="link-item" (click)="materialManageComponent()">物料</a>
                    <a class="link-item" (click)="traceability()">溯源</a>
				</div>
			</div>
			<div class="nav-item">
				<h1 class="title">应用方案</h1>
				<div class="link">
                    <a class="link-item" (click)="vegetables()">蔬菜</a>
					<a class="link-item" (click)="fruitTree()">果树</a>
					<a class="link-item" (click)="breed()">养殖</a>
				</div>
			</div>
			<div class="nav-item">
				<h1 class="title">资源</h1>
				<div class="link">
                    <a class="link-item" (click)="helpCenter()">帮助中心</a>
				</div>
			</div>
			<div class="nav-item">
				<h1 class="title">公司</h1>
				<div class="link">
                    <a class="link-item" (click)="userAgreement()">用户协议</a>
                    <a class="link-item" (click)="about()">关于我们</a>
				</div>
			</div>
		</div>
	</div>
	<app-copy-right></app-copy-right>
</footer>
<div class="fixed-btn"><a (click)="customerService()" class="btn-kf" target="_blank">客服</a><a href="javascript:void(0)" class="btn-zd">置顶</a></div>
<!-- 用户须知弹窗s -->
<div id="dialog-user-notice" class="dialog dialog-user-notice hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="dialog-title">饭碗科技付费用户须知</h1>
	<div class="body">
		<p style="margin-bottom: 38px;">饭碗科技一直致力于为用户提供文明健康、规范有序的网络环境，用户在使用饭碗科技服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于：伪造官方证书、非法博彩、色情、欺诈、暴力等违法违规内容，更多详情请阅读《饭碗科技付费用户协议》。</p>
		<p>对于违反或被视为违反《饭碗科技用户协议》的内容，饭碗科技将立即进行处理，包括但不限于：停止链接内容继续传播、删除相关内容、封停用户账号、将相应违规信息提交给相关单位、追究法律责任等措施，并在扫码后界面公告处理结果。</p>
	</div>
	<div class="btn-group">
		<a href="javascript:void(0)" class="btn-item dialog-close">取消</a>
		<a href="gmzt.html" class="btn-item btn-orange">同意并继续付款</a>
	</div>
	<div class="agreement">点击同意即代表已阅读并同意<a href="javascript:void(0)" (click)="userAgreement()">《饭碗科技付费用户协议》</a></div>
</div>
<!-- 用户须知弹窗e -->
<!-- 注册弹窗s -->
<div id="dialog-reg" class="dialog dialog-login hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="login-title">注册账号</h1>
	<h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
	<div class="container">
		<div class="form">
			<div class="form-wrap">
				<p class="form-title">手机号注册</p>
				<div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
				<div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)" class="btn-text">获取验证码</a></div>
				<div class="form-item"><input type="password" placeholder="密码（8-20位数字字母组合）"></div>
				<a href="javascript:void(0)" class="btn-reg dialog-close">立即注册</a>
			</div>
			<div class="agreement">
				<div class="check-btn"><span><input name="" type="checkbox" value=""></span>已阅读并同意<a (click)="customerService()"
					class="btn-text">《饭碗用户协议》</a>及<a href="javascript:void(0)" (click)="privacyAgreement()" class="btn-text">《隐私协议》</a></div>
			  </div>
		</div>
		<div class="ewm">
			<p class="ewm-title">微信快捷登录</p>
			<p class="ewm-img"><img src="assets/images/ewm.png" alt=""></p>
			<p class="ewm-prompt">无需验证，快捷登录</p>
		</div>
	</div>
	<div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text" onClick="changeDiaolg('#dialog-reg','#dialog-login')">直接登录</a></div>
</div>
<!-- 注册弹窗e -->
<!-- 登录弹窗s -->
<div id="dialog-login" class="dialog dialog-login hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="login-title">登录饭碗账号</h1>
	<h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
	<div class="container">
		<div class="form">
			<div class="form-wrap">
				<p class="form-title">手机号登录</p>
				<div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
				<div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)" class="btn-text">获取验证码</a></div>
				<div class="form-check">
					<div class="check-box"><div class="check-btn"><span><input name="" type="checkbox" value=""></span>自动登录</div></div>
					<a href="javascript:void(0)" (click)="forgetPassword()" class="btn-forget-pwd">忘记密码！</a>
				</div>
				<a href="javascript:void(0)" class="btn-reg dialog-close">登录</a>
			</div>
		</div>
		<div class="ewm">
			<p class="ewm-title">微信快捷登录</p>
			<p class="ewm-img"><img src="assets/images/ewm.png" alt=""></p>
			<p class="ewm-prompt">无需验证，快捷登录</p>
		</div>
	</div>
	<div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text" onClick="changeDiaolg('#dialog-login','#dialog-reg')">立即注册</a></div>
</div>
<!-- 登录弹窗e -->
<div id="dialog-bg" class="dialog-bg hidden"></div>
