<!-- <header class="header">
	<ul>
		<div class="logo"></div>
		<div class="menu">
			<li class="menu-item"><a class="menu-link"   href="javascript:void(0)" (click)="index()">首页</a></li>
			<li class="menu-item drop-down-menu">
				<a class="menu-link" href="javascript:void(0)">产品功能</a>
				<div class="drop-down-menu-wrap">
					<div class="drop-down-menu-box">
						<div class="sub-menu">
							<ul>
								<li  class="selected"><a  (click)="materialManageComponent()"><p class="name">物料管理</p><p>扫码即可出入库</p></a></li>
								<li><a (click)="liveFunction()"><p class="name">直播功能</p><p>可对接各大直播平台</p></a></li>
								<li><a (click)="dataAnalysis()"><p class="name">数据分析</p><p>可导出生产数据报表</p></a></li>
								<li><a  (click)="waterFertilizer()"><p class="name">水肥一体</p><p>通过系统控制水肥系统</p></a></li>
								<li><a (click)="soilMoisture()"><p class="name">土壤墒情</p><p>监控土壤墒情</p></a></li>
								<li><a (click)="climateMonitoring()"><p class="name">气候监控</p><p>随时随地都能查看</p></a></li>
								<li><a (click)="coldtracking()"><p class="name">冷链跟踪</p><p>可查看冷链车相关情况</p></a></li>

								<li><a (click)="traceability()"><p class="name">溯源功能</p><p>可生成溯源二维码</p></a></li>
							</ul>
						</div>
					</div>
				</div>
			</li>
			<li class="menu-item drop-down-menu">
				<a class="menu-link" href="javascript:void(0)">应用方案</a>
				<div class="drop-down-menu-wrap">
					<div class="drop-down-menu-box">
						<div class="sub-menu">
							<ul>
								<li><a (click)="vegetables()">
                                    <p class="name">蔬菜</p>
                                    <p>从容生产</p>
                                </a></li>
                            <li><a (click)="fruitTree()">
                                    <p class="name">果树</p>
                                    <p>高品质果树</p>
                                </a></li>
                            <li><a (click)="breed()">
                                    <p class="name">养殖</p>
                                    <p>智慧养殖</p>
                                </a></li>
							</ul>
						</div>
					</div>
				</div>
			</li>
			<li class="menu-item"><a class="menu-link"  href="javascript:void(0)" (click)="hardwareService()">硬件服务</a></li>
			<li class="menu-item"><a class="menu-link"   href="javascript:void(0)" (click)="price()">价格</a></li>
		</div>
		<div class="right">
			<a  href="javascript:void(0)" (click)="search()" class="btn-search">搜索</a>
			<a href="javascript:void(0)" class="btn-register" (click)="openDiaolg('#dialog-reg','.dialog-bg')">免费注册</a>
		</div>
	</ul>
</header>
<main class="gywn">
	<section class="banner">
		<div class="w-fixed">
			<p class="banner-title">溯源帮你的产品体现应有价值</p>
			<p>我们砥砺前行，践行农民的稳健与踏实</p>
		</div>
	</section>
	<nav class="Quick-navigation" id="Quick-navigation">
		<div class="w-fixed">
		  <a href="#gywmxx" class="Quick-navigation-item current">关于我们</a>
		  <a href="#fzlc" class="Quick-navigation-item">发展历程</a>
		  <a href="#sjyy" class="Quick-navigation-item">实践应用</a>
		  <a href="#zyrz" class="Quick-navigation-item">专业认证</a>
		  <a href="#lxfs" class="Quick-navigation-item">联系我们</a>
		  <div class="Scroll-progress-indicator"></div>
			<button class="Scroll-to-top"></button>
		</div>
	</nav>
	<section id="gywmxx" class="js-scroll-step gywmxx">
		<div class="w-fixed">
			<h1 class="header-title">关于我们</h1>
			<div class="body">
				<p>　　深圳饭碗农业物联网科技有限公司成立于2014年，专注于以科技发展农业生态，打造最优秀的智慧农业物联网云平台。</p>
				<p>　　在生产上，以云端连接农业物联网与大数据，传感设备稳定连接每一个作业流程点与作物、产品环境点，云端大数据分析处理解得最佳业务模型，辅助生产与决策。</p>
				<p>　　在运营上，通过智能控制+智慧管理的现代智慧农业模式，实现农业生产的标准化、精细化管理，达到了全面提升农产品品质及实现产品统一性的目的。</p>
				<p>　　饭碗科技通过农村信息化、农业智能化、农产品全过程追溯、农产品质量检测等技术研发和应用，在如何落实国家“藏粮于技”战略方面积累了丰富的经验。通过进行农产品品牌策划、搭建农产品电子交易平台等方式，解决了“农产品出村进城”问题通过打造“智慧农业服务”综合运营平台。</p>
			</div>
		</div>
	</section>
	<section id="fzlc" class="js-scroll-step fzlc">
		<div class="w-fixed">
			<h1 class="header-title">发展历程</h1>
			<h2 class="header-sub-title">从2014年饭碗科技的发展历程</h2>
			<div class="list">
				<div class="list-item">
					<div class="year"><span>2021</span></div>
					<p>整合物联网云平台—为无人农场提供服务</p>
					<p>实现流媒体对接各大直播平台，让农产品每时每刻都可以面对客户</p>
				</div>
				<div class="list-item">
					<div class="year"><span>2020</span></div>
					<p>冷链系统成功上线</p>
					<p>完成蔬菜版本溯源管理系统在成武金石的落地应用</p>
				</div>
				<div class="list-item">
					<div class="year"><span>2019</span></div>
					<p>完成养殖版本溯源管理系统在****的落地应用</p>
				</div>
				<div class="list-item">
					<div class="year"><span>2018</span></div>
					<p>开始研发养殖版本溯源管理系统</p>
				</div>
				<div class="list-item">
					<div class="year"><span>2017</span></div>
					<p>完成水果版本溯源管理系统在****的落地应用</p>
				</div>
				<div class="list-item">
					<div class="year"><span>2016</span></div>
					<p>开始研发水果版本溯源管理系统</p>
				</div>
				<div class="list-item">
					<div class="year"><span>2015</span></div>
					<p>搭建物联网云平台</p>
				</div>
				<div class="list-item">
					<div class="year"><span>2014</span></div>
					<p>深圳饭碗农业物联网科技有限公司</p>
				</div>
			</div>
		</div>
	</section>
	<section id="sjyy" class="js-scroll-step sjyy">
		<div class="w-fixed">
			<h1 class="header-title">实践应用</h1>
			<h2 class="header-sub-title">经过实践的考验，才是好东西</h2>
			<div class="card clearfix">
				<div class="card-item">
					<img src="assets/assets/images/sjyy_img_01.jpg" alt="">
					<p class="name">幸福村智慧养殖生态鹅基地</p>
				</div>
				<div class="card-item">
					<img src="assets/assets/images/sjyy_img_02.jpg" alt="">
					<p class="name">南部县东坝镇柑橘产业园</p>
				</div>
				<div class="card-item">
					<img src="assets/assets/images/sjyy_img_03.jpg" alt="">
					<p class="name">雅翠堡惠州水培基地</p>
				</div>
				<div class="card-item">
					<img src="assets/assets/images/sjyy_img_04.jpg" alt="">
					<p class="name">佰草庄园中草药</p>
				</div>
				<div class="card-item">
					<img src="assets/assets/images/sjyy_img_05.jpg" alt="">
					<p class="name">大橘果业平潭基地</p>
				</div>
				<div class="card-item">
					<img src="assets/assets/images/sjyy_img_06.jpg" alt="">
					<p class="name">成武金石始兴基地</p>
				</div>
			</div>
		</div>
	</section>
	<section id="zyrz" class="js-scroll-step zyrz">
		<div class="w-fixed">
			<h1 class="header-title">专业认证</h1>
			<h2 class="header-sub-title">经多年发展，斩获了相关认证资质</h2>
			<div class="card">
				<div class="card-item">
					<p class="pic"><img src="assets/assets/images/zyrz_img_01.jpg" alt=""></p>
					<dl class="content">
						<dt>南部县东坝镇柑橘产业园</dt>
						<dd>由欧亚体系认证服务（中国）有限公司颁发</dd>
						<dd>符合ISO 14001：2015环境管理体系国际标准</dd>
					</dl>
				</div>
				<div class="card-item">
					<p class="pic"><img src="assets/assets/images/zyrz_img_02.jpg" alt=""></p>
					<dl class="content">
						<dt>职业健康安全管理体系认证</dt>
						<dd>由欧亚体系认证服务（中国）有限公司颁发</dd>
						<dd>符合ISO 45001：2018职业健康安全管理体系国际标准</dd>
					</dl>
				</div>
				<div class="card-item">
					<p class="pic"><img src="assets/assets/images/zyrz_img_03.jpg" alt=""></p>
					<dl class="content">
						<dt>企业信用等级证书</dt>
						<dd>由华夏国众（北京）国际信用评价有限公司颁发</dd>
						<dd>企业信用等级为：3A</dd>
					</dl>
				</div>
				<div class="card-item">
					<p class="pic"><img src="assets/assets/images/zyrz_img_04.jpg" alt=""></p>
					<dl class="content">
						<dt>质量管理体系认证</dt>
						<dd>由欧亚体系认证服务（中国）有限公司颁发</dd>
						<dd>符合ISO 9001：2015质量管理体系国际标准</dd>
					</dl>
				</div>
			</div>
		</div>
	</section>
	<section id="lxfs" class="js-scroll-step lxfs">
		<div class="w-fixed">
			<div class="map">
				<img src="assets/assets/images/lxfs_img_map.png" alt="">
				<div class="content">
					<p class="name">饭碗科技</p>
					<p class="address">地址：广东省深圳市宝安区兴业路2005号 广兴源互联网创意园A座321</p>
				</div>
			</div>
			<div class="box">
				<p class="lxfs-title">联系我们</p>
				<div class="content">
					<p class="title">联系方式</p>
					<div class="body">
						<p><span class="label">市场合作：</span>451833096@qq.com</p>
						<p><span class="label">关于我们：</span>451833096@qq.com</p>
					</div>
					<div class="body">
						<p class="address"><span class="label">公司地址：</span>广东省深圳市宝安区兴业路2005号<br/>广兴源互联网创意园A座321</p>
					</div>
				</div>
			</div>
		</div>
	</section>

</main>
<footer class="footer">
	<div class="w-fixed">
		<div class="left">
			<div class="logo"></div>
			<p class="slogan">专致于打造好用的溯源系统</p>
			<div class="content">
				<p>客服时间：(8:30-19:00)</p>
				<p>商务合作：451833096@qq.com</p>
			</div>
		</div>
		<div class="nav">
			<div class="nav-item">
				<h1 class="title">产品功能</h1>
				<div class="link">
					<a class="link-item" (click)="liveFunction()">直播</a>
					<a class="link-item" (click)="materialManageComponent()">物料</a>
					<a class="link-item" (click)="traceability()">溯源</a>

				</div>
			</div>
			<div class="nav-item">
				<h1 class="title">应用方案</h1>
				<div class="link">
					<a class="link-item" (click)="vegetables()">蔬菜</a>
					<a class="link-item" (click)="fruitTree()">果树</a>
					<a class="link-item" (click)="breed()">养殖</a>
				</div>
			</div>
			<div class="nav-item">
				<h1 class="title">资源</h1>
				<div class="link">

					<a class="link-item" (click)="helpCenter()">帮助中心</a>
				</div>
			</div>
			<div class="nav-item">
				<h1 class="title">公司</h1>
				<div class="link">
					<a class="link-item" (click)="userAgreement()">用户协议</a>

					<a class="link-item"  (click)="about()">关于我们</a>
				</div>
			</div>
		</div>
	</div>
	<app-copy-right></app-copy-right>
</footer>

<div class="fixed-btn"><a (click)="customerService()" class="btn-kf" target="_blank">客服</a><a href="javascript:void(0)" class="btn-zd">置顶</a></div>

<div id="dialog-reg" class="dialog dialog-login hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="login-title">注册账号</h1>
	<h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
	<div class="container">
		<div class="form">
			<div class="form-wrap">
				<p class="form-title">手机号注册</p>
				<div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
				<div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)" class="btn-text">获取验证码</a></div>
				<div class="form-item"><input type="password" placeholder="密码（8-20位数字字母组合）"></div>
				<a href="javascript:void(0)" class="btn-reg dialog-close">立即注册</a>
			</div>
			<div class="agreement">
				<div class="check-btn"><span><input name="" type="checkbox" value=""></span>已阅读并同意<a (click)="customerService()"
					class="btn-text">《饭碗用户协议》</a>及<a href="javascript:void(0)" (click)="privacyAgreement()" class="btn-text">《隐私协议》</a></div>
			  </div>
		</div>
		<div class="ewm">
			<p class="ewm-title">微信快捷登录</p>
			<p class="ewm-img"><img src="assets/assets/images/ewm.png" alt=""></p>
			<p class="ewm-prompt">无需验证，快捷登录</p>
		</div>
	</div>
	<div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text" onClick="changeDiaolg('#dialog-reg','#dialog-login')">直接登录</a></div>
</div>

<div id="dialog-login" class="dialog dialog-login hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="login-title">登录饭碗账号</h1>
	<h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
	<div class="container">
		<div class="form">
			<div class="form-wrap">
				<p class="form-title">手机号登录</p>
				<div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
				<div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)" class="btn-text">获取验证码</a></div>
				<div class="form-check">
					<div class="check-box"><div class="check-btn"><span><input name="" type="checkbox" value=""></span>自动登录</div></div>
					<a href="javascript:void(0)" (click)="forgetPassword()" class="btn-forget-pwd">忘记密码！</a>
				</div>
				<a href="javascript:void(0)" class="btn-reg dialog-close">登录</a>
			</div>
		</div>
		<div class="ewm">
			<p class="ewm-title">微信快捷登录</p>
			<p class="ewm-img"><img src="assets/assets/images/ewm.png" alt=""></p>
			<p class="ewm-prompt">无需验证，快捷登录</p>
		</div>
	</div>
	<div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text" onClick="changeDiaolg('#dialog-login','#dialog-reg')">立即注册</a></div>
</div>

<div id="dialog-bg" class="dialog-bg hidden"></div> -->


<header class="header">
  <ul>
    <div class="logo"></div>
    <div class="menu">
      <li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="index()">首页</a></li>
      <li class="menu-item selected drop-down-menu">
        <a class="menu-link" href="javascript:void(0)">产品功能</a>
        <div class="drop-down-menu-wrap">
          <div class="drop-down-menu-box">
            <div class="sub-menu">
              <ul>
                <!-- <li class="selected"><a href="wlgl.html"><p class="name">物料管理</p><p>扫码即可出入库</p></a></li>
								<li><a href="zbgn.html"><p class="name">直播功能</p><p>可对接各大直播平台</p></a></li>
								<li><a href="sjfx.html"><p class="name">数据分析</p><p>可导出生产数据报表</p></a></li>
								<li><a href="sfyt.html"><p class="name">水肥一体</p><p>通过系统控制水肥系统</p></a></li>
								<li><a href="trsq.html"><p class="name">土壤墒情</p><p>监控土壤墒情</p></a></li>
								<li><a href="qhjk.html"><p class="name">气候监控</p><p>随时随地都能查看</p></a></li>
								<li><a href="llgz.html"><p class="name">冷链跟踪</p><p>可查看冷链车相关情况</p></a></li>
								<li><a href="sygn.html"><p class="name">溯源功能</p><p>可生成溯源二维码</p></a></li> -->

                <li class="selected"><a href="javascript:void(0)" (click)="materialManageComponent()">
                    <p class="name">物料管理</p>
                    <p>扫码即可出入库</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="liveFunction()">
                    <p class="name">直播功能</p>
                    <p>可对接各大直播平台</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="dataAnalysis()">
                    <p class="name">数据分析</p>
                    <p>可导出生产数据报表</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="waterFertilizer()">
                    <p class="name">水肥一体</p>
                    <p>通过系统控制水肥系统</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="soilMoisture()">
                    <p class="name">土壤墒情</p>
                    <p>监控土壤墒情</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="climateMonitoring()">
                    <p class="name">气候监控</p>
                    <p>随时随地都能查看</p>
                  </a></li>
                <li><a href="javascript:void(0)" (click)="coldtracking()">
                    <p class="name">冷链跟踪</p>
                    <p>可查看冷链车相关情况</p>
                  </a></li>

                <li><a href="javascript:void(0)" (click)="traceability()">
                    <p class="name">溯源功能</p>
                    <p>可生成溯源二维码</p>
                  </a></li>

              </ul>
            </div>
          </div>
        </div>
      </li>
      <li class="menu-item drop-down-menu">
        <a class="menu-link" href="javascript:void(0)">应用方案</a>
        <div class="drop-down-menu-wrap">
          <div class="drop-down-menu-box">
            <div class="sub-menu">
              <ul>
                <ul>
                  <li><a href="javascript:void(0)" (click)="vegetables()">
                      <p class="name">蔬菜</p>
                      <p>从容生产</p>
                    </a></li>
                  <li><a href="javascript:void(0)" (click)="fruitTree()">
                      <p class="name">果树</p>
                      <p>高品质果树</p>
                    </a></li>
                  <li><a href="javascript:void(0)" (click)="breed()">
                      <p class="name">养殖</p>
                      <p>智慧养殖</p>
                    </a></li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </li>
      <li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="hardwareService()">硬件服务</a></li>
      <!-- <li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="price()">价格</a></li> -->
    </div>
    <div class="right">
      <a href="javascript:void(0)" (click)="search()" class="btn-search">搜索</a>
      <a href="javascript:void(0)" class="btn-register" (click)="openDiaolg('#dialog-reg','.dialog-bg')">免费注册</a>
    </div>
  </ul>
</header>
<main class="wlgl">
  <section class="banner">
    <div class="w-fixed">
      <div class="pic"><img src="assets/images/wlgl_banner_img.png" alt=""></div>
      <div class="content">
        <div class="title">数据稳定、方便快捷</div>
        <div class="body">数据从采购出发、便于财务对账<br />随时导出你想要的物料报表</div>
      </div>
    </div>
  </section>
  <section class="cpld">
    <div class="w-fixed">
      <h1 class="header-title">产品亮点</h1>
      <h2 class="header-sub-title">智能 | 效率 | 精细</h2>
      <div class="container">
        <div class="left">
          <div class="title">主要优点</div>
          <div class="content">
            <p>物料从申购做起，明确责任人，拒绝纰漏</p>
            <p>使用小程序/App扫码二维码即可操作进销存</p>
            <p>利用二维码给仓库分库位，方便后续精确查找物料位置</p>
            <p>其中包括采购、人事、进销存等多种功能</p>
            <p>数据每天进行备份，无数据丢失风险</p>
            <p>数据每天进行备份，无数据丢失风险</p>
          </div>
          <a href="javascript:void(0)" class="btn-blue-text" (click)="customerService()">咨询客服</a>
        </div>
        <div class="img">
          <img src="assets/images/wlgl_img_cpld.jpg" alt="">
        </div>
      </div>
    </div>
  </section>
  <section class="gnlc">
    <div class="w-fixed">
      <h1 class="header-title">功能流程简介</h1>
      <h2 class="header-sub-title">专注于农业物料系统的应用</h2>
      <div class="pic">
        <img src="assets/images/wlgl_img_gnlc.png" alt="">
      </div>
    </div>
  </section>
  <section class="aqbz">
    <div class="w-fixed">
      <h1 class="header-title">安全保障</h1>
      <h2 class="header-sub-title">与用户签订保密协议</h2>
      <div class="container">
        <div class="cell">
          <div class="title">存储安全</div>
          <div class="cell-item">
            <p class="icon"><img src="assets/images/wlgl_icon_aqbz_01.png" alt=""></p>
            <div class="content">
              <p class="name">数据库</p>
              <p class="body">采用阿里云聚石塔（现零售云）RDS，主从热备版本</p>
            </div>
          </div>
          <div class="cell-item">
            <p class="icon"><img src="assets/images/wlgl_icon_aqbz_02.png" alt=""></p>
            <div class="content">
              <p class="name">缓存</p>
              <p class="body">采用阿里云聚石塔（现零售云）Redis，主从版本</p>
            </div>
          </div>
          <div class="cell-item">
            <p class="icon"><img src="assets/images/wlgl_icon_aqbz_03.png" alt=""></p>
            <div class="content">
              <p class="name">文件存储</p>
              <p class="body">采用阿里云OSS文件存储系统</p>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="title">数据安全</div>
          <div class="cell-item">
            <p class="icon"><img src="assets/images/wlgl_icon_aqbz_04.png" alt=""></p>
            <div class="content">
              <p class="name">敏感字段加密</p>
              <p class="body">在数据存储、前端显示、操作日志等场景均采用脱敏技术进行处理，保障数据资产的完整性和保密性</p>
            </div>
          </div>
          <div class="cell-item">
            <p class="icon"><img src="assets/images/wlgl_icon_aqbz_05.png" alt=""></p>
            <div class="content">
              <p class="name">数据热设备恢复</p>
              <p class="body">对于持久化数据，均采用主从热备技术，避免因系统崩溃或断电等导致数据丢失</p>
            </div>
          </div>
          <div class="cell-item">
            <p class="icon"><img src="assets/images/wlgl_icon_aqbz_06.png" alt=""></p>
            <div class="content">
              <p class="name">会员数据隔离</p>
              <p class="body">系统针对每个会员，单独建立数据库，在物理上进行数据隔离，保证不同会员之间的数据安全性</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="gmzx">
    <div class="w-fixed">
      <h1 class="header-title">购买咨询</h1>
      <div class="card">
        <div class="card-item">
          <p class="name">在线人工客服</p>
          <p class="content">带你试用系统,体念其便捷</p>
          <a href="javascript:void(0)" class="btn" (click)="customerService()">现在咨询</a>
        </div>
        <!-- <div class="card-item">
          <p class="name">现在购买</p>
          <p class="price">￥<span class="f-big">1200</span>/年</p>
          <a href="javascript:void(0)" class="btn" (click)="buyBowMateralManage()">立即购买</a>
        </div> -->
      </div>
    </div>
  </section>


</main>
<footer class="footer">
  <div class="w-fixed">
    <div class="left">
      <div class="logo"></div>
      <p class="slogan">专致于打造好用的溯源系统</p>
      <div class="content">
        <p>客服时间：(8:30-19:00)</p>
        <p>商务合作：451833096@qq.com</p>
      </div>
    </div>
    <div class="nav">
      <div class="nav-item">
        <h1 class="title">产品功能</h1>
        <div class="link">
          <a class="link-item" href="javascript:void(0)" (click)="liveFunction()">直播</a>
          <a class="link-item" href="javascript:void(0)" (click)="materialManageComponent()">物料</a>
          <a class="link-item" href="javascript:void(0)" (click)="traceability()">溯源</a>

        </div>
      </div>
      <div class="nav-item">
        <h1 class="title">应用方案</h1>
        <div class="link">
          <a class="link-item" href="javascript:void(0)" (click)="vegetables()">蔬菜</a>
          <a class="link-item" href="javascript:void(0)" (click)="fruitTree()">果树</a>
          <a class="link-item" href="javascript:void(0)" (click)="breed()">养殖</a>
        </div>
      </div>
      <div class="nav-item">
        <h1 class="title">资源</h1>
        <div class="link">

          <a class="link-item" href="javascript:void(0)" (click)="helpCenter()">帮助中心</a>
        </div>
      </div>
      <div class="nav-item">
        <h1 class="title">公司</h1>
        <div class="link">
          <a class="link-item" href="javascript:void(0)" (click)="userAgreement()">用户协议</a>

          <a class="link-item" href="javascript:void(0)" (click)="about()">关于我们</a>
        </div>
      </div>
    </div>
  </div>
  <app-copy-right></app-copy-right>
</footer>
<div class="fixed-btn"><a (click)="customerService()" class="btn-kf" target="_blank">客服</a><a href="javascript:void(0)"
    class="btn-zd">置顶</a></div>

<!-- <div class="fixed-btn"><a href="javascript:void(0)" class="btn-kf" target="_blank" (click)="customerService()">客服</a><a href="javascript:void(0)" class="btn-zd">置顶</a></div> -->
<!-- 注册弹窗s -->
<div id="dialog-reg" class="dialog dialog-login hidden">
  <a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
  <h1 class="login-title">注册账号</h1>
  <h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
  <div class="container">
    <div class="form">
      <div class="form-wrap">
        <p class="form-title">手机号注册</p>
        <div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
        <div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)"
            class="btn-text">获取验证码</a></div>
        <div class="form-item"><input type="password" placeholder="密码（8-20位数字字母组合）"></div>
        <a href="javascript:void(0)" class="btn-reg dialog-close">立即注册</a>
      </div>
      <div class="agreement">
        <div class="check-btn"><span><input name="" type="checkbox" value=""></span>已阅读并同意<a (click)="customerService()"
            class="btn-text">《饭碗用户协议》</a>及<a href="javascript:void(0)" (click)="privacyAgreement()"
            class="btn-text">《隐私协议》</a></div>
      </div>
    </div>
    <div class="ewm">
      <p class="ewm-title">微信快捷登录</p>
      <p class="ewm-img"><img src="assets/assets/images/ewm.png" alt=""></p>
      <p class="ewm-prompt">无需验证，快捷登录</p>
    </div>
  </div>
  <div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text"
      onClick="changeDiaolg('#dialog-reg','#dialog-login')">直接登录</a></div>
</div>

<div id="dialog-login" class="dialog dialog-login hidden">
  <a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
  <h1 class="login-title">登录饭碗账号</h1>
  <h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
  <div class="container">
    <div class="form">
      <div class="form-wrap">
        <p class="form-title">手机号登录</p>
        <div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
        <div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)"
            class="btn-text">获取验证码</a></div>
        <div class="form-check">
          <div class="check-box">
            <div class="check-btn"><span><input name="" type="checkbox" value=""></span>自动登录</div>
          </div>
          <a href="javascript:void(0)" (click)="forgetPassword()" class="btn-forget-pwd">忘记密码！</a>
        </div>
        <a href="javascript:void(0)" class="btn-reg dialog-close">登录</a>
      </div>
    </div>
    <div class="ewm">
      <p class="ewm-title">微信快捷登录</p>
      <p class="ewm-img"><img src="assets/assets/images/ewm.png" alt=""></p>
      <p class="ewm-prompt">无需验证，快捷登录</p>
    </div>
  </div>
  <div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text"
      onClick="changeDiaolg('#dialog-login','#dialog-reg')">立即注册</a></div>
</div>
<!-- 登录弹窗e -->
<div id="dialog-bg" class="dialog-bg hidden"></div>
