<header class="header">
    <ul>
        <div class="logo"></div>
        <div class="menu">
            <li class="menu-item"><a class="menu-link"  href="javascript:void(0)" (click)="index()">首页</a></li>
            <li class="menu-item drop-down-menu">
                <a class="menu-link" href="javascript:void(0)">产品功能</a>
                <div class="drop-down-menu-wrap">
                    <div class="drop-down-menu-box">
                        <div class="sub-menu">
                            <ul>
                                <li><a href="javascript:void(0)" (click)="materialManageComponent()"><p class="name">物料管理</p>
                                    <p>扫码即可出入库</p>
                                  </a></li>
                                <li><a href="javascript:void(0)" (click)="liveFunction()">
                                    <p class="name">直播功能</p>
                                    <p>可对接各大直播平台</p>
                                  </a></li>
                                <li><a href="javascript:void(0)" (click)="dataAnalysis()">
                                    <p class="name">数据分析</p>
                                    <p>可导出生产数据报表</p>
                                  </a></li>
                                <li><a href="javascript:void(0)" (click)="waterFertilizer()">
                                    <p class="name">水肥一体</p>
                                    <p>通过系统控制水肥系统</p>
                                  </a></li>
                                <li><a href="javascript:void(0)" (click)="soilMoisture()">
                                    <p class="name">土壤墒情</p>
                                    <p>监控土壤墒情</p>
                                  </a></li>
                                <li><a href="javascript:void(0)" (click)="climateMonitoring()">
                                    <p class="name">气候监控</p>
                                    <p>随时随地都能查看</p>
                                  </a></li>
                                <li><a href="javascript:void(0)" (click)="coldtracking()">
                                    <p class="name">冷链跟踪</p>
                                    <p>可查看冷链车相关情况</p>
                                  </a></li>
                                <!-- <li><a href="dpjk.html">
                                    <p class="name">大屏监控功能</p>
                                    <p>通过大屏即知生产情况</p>
                                  </a></li> -->
                                <li><a href="javascript:void(0)" (click)="traceability()">
                                    <p class="name">溯源功能</p>
                                    <p>可生成溯源二维码</p>
                                  </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
            <li class="menu-item drop-down-menu">
                <a class="menu-link" href="javascript:void(0)">应用方案</a>
                <div class="drop-down-menu-wrap">
                    <div class="drop-down-menu-box">
                        <div class="sub-menu">
                            <ul>
                                <li><a  href="javascript:void(0)" (click)="vegetables()">
                                    <p class="name">蔬菜</p>
                                    <p>从容生产</p>
                                  </a></li>
                                <li><a href="javascript:void(0)" (click)="fruitTree()">
                                    <p class="name">果树</p>
                                    <p>高品质果树</p>
                                  </a></li>
                                <li><a href="javascript:void(0)"(click)="breed()">
                                    <p class="name">养殖</p>
                                    <p>智慧养殖</p>
                                  </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
            <li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="hardwareService()">硬件服务</a></li>
      <!-- <li class="menu-item selected"><a class="menu-link" href="javascript:void(0)" (click)="price()">价格</a></li> -->
        </div>
        <div class="right">
            <a href="javascript:void(0)" (click)="search()" class="btn-search">搜索</a>
            <a href="javascript:void(0)" class="btn-register" onClick="openDiaolg('#dialog-reg','.dialog-bg')">免费注册</a>
            <!-- 我的s -->
            <div class="mine">
                <div class="avatar"><i class="icon-avatar"></i><i class="icon-arrow"></i></div>
                <div class="mine-popup">
                    <div class="mine-popup-wrap">
                        <div class="user-info">
                            <p class="tel">13352928035</p>
                            <p class="version">免费版<span class="btn-upgrade">升级</span></p>
                        </div>
                        <div class="nav">
                            <!-- <a href="wddd.html" class="nav-item"><i class="icon icon-dd"></i>我的订单</a>
                            <a href="wddz.html" class="nav-item"><i class="icon icon-dz"></i>我的地址</a>
                            <a href="zhxx.html" class="nav-item"><i class="icon icon-zh"></i>账户信息</a>
                            <a href="javascript:void(0)" class="nav-item" id="aqtc"><i class="icon icon-tc"></i>安全退出</a> -->

                            <div class="nav">
                                <a href="javascript:void(0)" (click)="myOrder()" class="nav-item"><i class="icon icon-dd"></i>我的订单</a>
                                <a  href="javascript:void(0)" (click)="myAddress()"  class="nav-item"><i class="icon icon-dz"></i>我的地址</a>
                                <a href="javascript:void(0)"   (click)="accountInfo()" class="nav-item"><i class="icon icon-zh"></i>账户信息</a>
                                <a href="javascript:void(0)" (click)="index()" class="nav-item" id="aqtc"><i class="icon icon-tc"></i>安全退出</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 我的e -->
        </div>
    </ul>
</header>
<main class="ysxy bg-light-green">
    <div class="w-fixed">
        <div class="container">
            <h1 class="title">隐私协议</h1>
            <p class="date">更新日期：2021年08月16日<br />生效日期：2021年08月16日</p>
            <p>本协议双方当事人为：<br />深圳饭碗农业物联网科技有限公司（以下简称“饭碗科技”或“我们”）<br />饭碗科技云平台的用户（以下简称“用户”或“您”）</p>
            <p>本隐私协议在您使用饭碗科技云平台产品与服务或者在您以其他方式调用饭碗科技云平台服务时全程有效。在接受本协议之前，请您仔细阅读全部内容，如果您无法准确理解或不同意本协议的任一内容，请不要访问、使用饭碗科技云平台或饭碗科技提供的任何其他服务。您通过网络页面点击同意、确认、提交、实际使用饭碗科技云平台或饭碗科技提供的其他服务等操作，均表示您已阅读并充分理解本协议之内容，并受其约束。
            </p>
            <h2 class="f-bold">一、我们收集的信息</h2>
            <p>我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。</p>
            <p>1.1 注册饭碗科技账号
                、文件、表单信息、表单收集的记录信息、地理位置信息、企业认证信息、与饭碗账号绑定的微信号等。<br />特别提醒，当您作为表单填写者使用表单相关功能时，表单中可能包含表单创建者设定的、需要您主动填写的个人信息。例如，姓名、身份证号码、住址、通信通讯联系方式、银行账号、地理位置、录音录像等。您一旦主动填写并提交后，即视为您授权该表单收集您填写的信息，相关的个人信息即归于表单创建者控制。您应自行判断提供个人信息的相应风险。如您拒绝向表单创建者提供个人信息，请勿填写并提交表单。如果您对以上表单创建者控制的个人信息的收集目的、范围和使用方式有任何疑问或意见，您理解并同意联系该表单创建者处理。
            </p>
            <p>1.3
                您通过我们的支持服务所提供的信息<br />我们将收集您通过我们的客服支持服务或者其他支持服务所提交的问题与信息。当您打开我们的客服支持聊天窗口或是发送邮件时，我们可能要求您提供联系信息、您所遇到的问题、问题截图、操作录像以及其他有助于解决问题的文档、截屏、音视频等信息。
            </p>
            <p>1.4
                付费相关信息<br />为了帮助您顺利完成饭碗科技云平台付费版本的支付、退款、发票送达，并在付费期间持续为您提供客服、技术支持等服务，我们可能会要求您提供付款方名称、联系方式、邮箱地址、通讯地址及支付，退款账号等信息。
            </p>
            <p>1.5 使用服务过程中我们会收集以下信息<br />1.5.1
                日志信息<br>当您访问我们的任何服务并与之互动时，我们会自动收集您对我们服务的使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器类型、使用的语言、访问日期及时间以及您访问的网页记录等。<br>1.5.2
                设备与连接信息<br>我们会根据您在产品使用过程中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本）、设备所在位置相关信息（例如IP地址以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站等传感器信息）。<br>1.5.3
                位置信息<br>您开启设备定位功能并使用我们基于位置提供的相关产品服务时，在获得您的授权同意后，我们会收集有关您位置的信息，例如：您通过具有定位功能的移动设备使用我们的表单定位功能时，我们通过GPS或WiFi等方式收集的您的地理位置信息。您可以通过关闭定位功能随时停止我们对您的地理位置信息的收集，但之后您可能将无法使用相关服务或功能，或者无法达到相关服务拟达到的效果。
            </p>
            <p>1.6 我们从其他来源渠道收集到的信息<br>我们会从服务中的其他用户、其他第三方服务和相关公司以及我们的业务和渠道合作伙伴那里收集到有关您的信息。<br>1.6.1
                服务中的其他用户<br>我们服务的其他用户可能会在提交内容时提供有关您的信息。</p>
            <p>1.6.2
                您以其他方式关联登录或使用服务<br>若您以其他方式（如微信）关联登录、使用我们的服务，我们会向第三方请求您的个人信息，如果您同意提供，此时您将授权我们读取您在该第三方账号注册的相关信息（如昵称、头像、手机号等）。
            </p>
            <p>1.7 征得授权同意的例外<br>请注意：在以下情形中，我们收集个人信息无需事先征得您的授权同意：<br>1.7.1 与个人信息控制者履行法律法规规定的义务相关的；<br>1.7.2
                与国家安全、国防安全直接相关的；<br>1.7.3 与公共安全、公共卫生、重大公共利益直接相关的；<br>1.7.4 与刑事侦查、起诉、审判和判决执行等直接相关的；<br>1.7.5
                出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；<br>1.7.6 所涉及的个人信息是个人信息主体自行向社会公众公开的；<br>1.7.7
                根据个人信息主体要求签订和履行合同所必需的；<br>1.7.8 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br>1.7.9
                维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障。</p>
            <h2 class="f-bold">二、我们如何使用收集的信息</h2>
            <p>我们严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途。若我们超出以下用途使用您的信息，我们将再次向您进行说明，并征得您的同意。</p>
            <p>2.1
                为您提供服务<br>为了向您提供服务，我们会向您发送信息、通知或与您进行业务沟通。包括但不限于为保证服务完成所必须的验证码、使用服务时所必要的推送通知。我们将使用您的联系信息及绑定的微信号，通过邮件、短信、公众号推送等方式向您发送通知。包括您的账号扫描统计数据、回应您的意见问题与需求、提供客服支持以及向您发送更新、通知、提醒、警告等。
            </p>
            <p>2.2
                系统与数据安全<br>为了保证系统与数据安全，我们将运用各种安全技术和程序，或通过购买第三方产品服务，提升系统稳定性，保障数据真实完整可验证，以防止数据的丢失、未经授权访问或披露。同时为帮助我们更好地了解系统的运行情况，我们可能记录相关信息。例如，您使用饭碗科技云平台的频率、故障数据、总体使用情况、性能数据以及使用终端的来源。当我们的系统发生故障时，我们会记录和分析系统故障时产生的信息，优化我们的服务。
            </p>
            <p>2.3 身份验证及安全保障<br>我们使用有关您和您的服务使用情况信息来验证账号和活动、监控可疑或欺诈活动。</p>
            <p>我们使用有关您和您的服务使用情况信息来验证账号和活动、监控可疑或欺诈活动。<br>我们一直在寻找方法使我们的服务以更智能、更快速、更安全的方式来帮助您获得更好的使用体验。我们会了解用户如何使用我们的产品与服务，并不断收集用户直接向我们提供的反馈，以帮助我们排查问题并确定产品逻辑、功能、以及服务整合和改进的方式。例如：我们在每次迭代与更新时，会记录新功能的使用量以及用户的使用习惯，从而设计更好、更人性化的功能。但这些信息并不会包含您的任何身份识别信息。
            </p>
            <p>2.5 用于支持服务<br>我们使用您提交的信息来解决您遇到的技术问题、响应您的协助请求、分析崩溃信息并进行修复和改进服务。</p>
            <p>2.6 邀请您参与有关我们服务的调查<br>为了让您有更好的体验、改善我们的服务或经您同意的其他用途，在符合相关法律法规的前提下，我们可能邀请您参与产品的调研，反馈，问卷收集，线下沙龙等活动。
                在此类活动中，我们可能需要用户提供身份信息或地址，以便我们能够核验活动参与人身份或寄送礼品。是否参与此类活动将由您全权决定。</p>
            <p>2.7 征得授权同意的例外<br>请注意：在以下情形中，我们使用个人信息无需事先征得您的授权同意：<br>2.7.1 与个人信息控制者履行法律法规规定的义务相关的；<br>2.7.2
                与国家安全、国防安全直接相关的；<br>2.7.3 与公共安全、公共卫生、重大公共利益直接相关的；<br>2.7.4 与刑事侦查、起诉、审判和判决执行等直接相关的；<br>2.7.5
                出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；<br>2.7.6 所涉及的个人信息是个人信息主体自行向社会公众公开的；<br>2.7.7
                根据个人信息主体要求签订和履行合同所必需的；<br>2.7.8 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br>2.7.9
                维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障。</p>
            <h2 class="f-bold">三、我们如何使用 Cookie 和同类技术</h2>
            <p>Cookie是一种可让网站服务器将数据存储于客户端或从客户端中读取数据的中立技术。Cookie文件是存储了一些与用户访问网站有关信息的文件，格式为.txt，内容通常均经过加密。安全的Cookie技术会为用户在上网时提供更为便捷的服务。
            </p>
            <p>3.1
                我们如何使用Cookie<br>为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会使用您的计算机或移动设备的Cookie存储技术保存数据。Cookie通常包含标识符、站点名称以及一些号码和字符。
            </p>
            <p>3.1.1 身份验证<br>借助Cookie，可以使我们验证您的身份信息，以确保您的帐号安全。</p>
            <p>3.1.2 偏好设置<br>Cookie可帮助我们按照您所希望的服务样式、个性化设置为您提供服务。<br>例如，Cookie技术可记录您是否已经阅读过一些提示性的文字，防止重复提示对您造成骚扰。</p>
            <p>3.1.3 产品功能服务<br>Cookie可帮助我们为用户提供更好的产品和服务。<br>例如，Cookie可记录您上一次登出账号时的状态，当您再次登录账号时，回到上一次操作的页面中，提高您的使用效率。</p>
            <p>例如，Cookie可记录您上一次登出账号时的状态，当您再次登录账号时，回到上一次操作的页面中，提高您的使用效率。<br>我们使用Cookie来了解、改善我们的产品和服务，为新产品或功能的研发提供参考数据。<br>例如，我们会使用Cookie来了解您使用我们服务的方式，以求改善用户体验。例如您在进入我们网页时，在各个步骤所耗费的时间，我们会收集此信息用以分析研究能够进一步优化和改善的方面。
            </p>
            <p>3.2
                如何禁用Cookie<br>我们不会将Cookie用于本协议所述目的之外的任何用途。您可根据自己的偏好管理Cookie，您也可以清除计算机上保存的所有Cookie。大部分网络浏览器都设有阻止Cookie的功能。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面
            </p>
            <h2 class="f-bold">四、转让与对政府披露您的信息</h2>
            <p>4.1 转让<br>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br>4.4.1 获得您的明确同意后，我们会向其他方转让您的个人信息；<br>4.4.2
                如果饭碗科技发生合并、收购或破产清算情形，将可能涉及到个人信息转让，此种情况下我们会要求新的持有您个人信息的公司、组织继续受本协议的约束。如果本协议中约定的个人信息的收集、处理方式发生任何改变，该公司、组织将重新向您征求授权同意。
            </p>
            <p>4.2基于法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。但我们保证，在上述情况发生时，我们会要求披露请求方必须出具与之相应的有效法律文件，并对被披露的信息采取符合法律和业界标准的安全防护措施。
            </p>
            <p>4.3 征得授权同意的例外<br>以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：<br>4.3.1 与个人信息控制者履行法律法规规定的义务相关的；<br>4.3.2
                与国家安全、国防安全直接相关的；<br>4.3.3 与公共安全、公共卫生、重大公共利益直接相关的；<br>4.3.4 与刑事侦查、起诉、审判和判决执行等直接相关的；<br>4.3.5
                出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；<br>4.3.6 个人信息主体自行向社会公众公开的个人信息；<br>4.3.7
                从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
            <h2 class="f-bold">五、我们如何存储收集到的信息并如何保障其安全性</h2>
            <p>5.1
                信息的存储和安全：<br>饭碗科技非常重视您的信息安全。我们努力采取各种合理的安全措施来保护您的信息安全，防止信息遭受到未经授权的访问、公开披露、使用、修改、损坏或丢失。<br>我们通过多点容灾备份、数据传输加密等措施提高数据的安全性；建立以阿里云为基础的可视化的安全中心系统，包含ECS云盾、态势感知、安全日志系统、WEB应用防火墙等；同时我们会举办安全和隐私保护培训，加强员工对于保护信息重要性的认识。更多详细内容，您可以查看饭碗科技云平台《数据安全》。<br>请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。因此，我们强烈建议您采取积极措施保护您的信息安全，包括但不限于使用复杂密码、定期修改密码、不将您的账号密码等私密信息透露给他人。<br>若不幸发生信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大。同时将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告
            </p>
            <p>5.2
                个人信息存储期限：<br>一般而言，我们只会在达成本协议所述目的所需的期限内保留您的个人信息。例如：<br>手机号码：若您需要使用饭碗科技云平台，我们需要一直保存您的手机号码，以保证您正常使用，当您注销饭碗科技云平台帐户后，我们将删除相应的信息；<br>表单信息：当您通过表单收集信息后，我们需要保存您的表单信息，以保证数据的查看与导出。当您删除您的表单信息后，我们将删除相应的信息。<br>除法律法规另有规定外，在您注销饭碗账号后，我们会对您的个人信息进行删除处理。
            </p>
            <p>5.3
                停止运营<br>如果我们的产品或服务发生停止运营的情形，我们会采取合理措施保护您的个人信息安全，包括及时停止继续收集个人信息的活动；将停止运营的通知以推送、公告等形式通知您，并在合理的期限内对所持有的个人信息进行删除或匿名化处理。
            </p>
            <h2 class="f-bold">六、有害信息的过滤和删除</h2>
            <p>根据相关法律的规定，饭碗科技云平台禁止用户创建和储存一切有害信息，包括：<br>(1) 违反宪法确定的基本原则的；<br>(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br>(3)
                损害国家荣誉和利益的；<br>(4) 煽动民族仇恨、民族歧视，破坏民族团结的；<br>(5) 破坏国家宗教条款，宣扬邪教和封建迷信的；<br>(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；<br>(7)
                散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；<br>(8) 侮辱或者诽谤他人，侵害他人合法权益的；<br>(9) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；<br>(10)
                以非法民间组织名义活动的；</p>
            <h2 class="f-bold">七、您管理个人信息的权利</h2>
            <p>我们非常重视您对个人信息的关注，并尽全力保护您对于自己个人信息访问、更正及删除的权利，以使您拥有充分的能力保障您的隐私和安全。 您的权利包括：</p>
            <p>7.1 访问和更正您的个人信息<br>您可以通过【账号信息】-【用户账号】，访问、更正您提供的手机号、企业信息、登录密码、绑定的微信号。</p>
            <p>7.2
                注销账号<br>您可以发送账号注销申请书至：451833096@qq.com  进行注销操作。具体操作详情请您：查看账号注销流程。<br>请您注意：饭碗账号一旦注销，您将无法登录、使用该账号，也就是说您将无法再以此账号登录/使用/继续使用饭碗科技云平台的相关产品与服务。当您注销帐号后，除法律法规另有规定或监管部门另有要求外，账号内所有内容、信息、数据、记录将会被删除或匿名化处理，您也无法再检索、访问、获取、继续使用和找回，也无权要求我们找回。
            </p>
            <p>7.3
                身份验证及费用说明<br>为保障安全，您可能需要以提供书面材料或其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于您的合理请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定的成本费用。对于那些无端重复、需要过多技术手段（例如：需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
            </p>
            <p>7.4响应您上述请求的例外<br>在以下情形中，按照法律法规要求，我们将无法响应您的更正、删除、注销信息等请求：<br>7.4.1 与个人信息控制者履行法律法规规定的义务相关的；<br>7.4.2
                国家安全、国防安全直接相关的；<br>7.4.3 与公共安全、公共卫生、重大公共利益直接相关的；<br>7.4.4 与刑事侦查、起诉、审判和执行判决等直接相关的；<br>7.4.5
                有充分证据表明您存在主观恶意或滥用权利的；<br>7.4.6 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；<br>7.4.7
                响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；<br>7.4.8 涉及商业秘密的。</p>
            <h2 class="f-bold">八、未成年人保护</h2> <p>
            我们主要面向成年人提供产品和服务，如果没有父母或者监护人的同意，未成年人不得创建自己的饭碗账号或者向通过饭碗科技云平台表单及相关功能提供个人信息。对于经父母或监护⼈同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。<br>如果您为14周岁以下的未成年人，我们要求您请您的父母或监护人仔细阅读本协议，并在征得父母或监护人同意的前提下使用我们的服务或向我们提供信息。
            </p>
            <h2 class="f-bold">九、隐私协议的变更</h2>
            <p>9.1
                我们可能会适时对本隐私协议进行修订。当本协议的条款发生变更时，我们会在上本页面发布更新后的隐私协议。若您在新版本隐私协议和用户协议生效后继续使用我们的产品和服务，即表示您已充分阅读并愿意受更新后的隐私协议和约束，若您不同意该等变更请立即停止使用我们的产品和服务。<br>9.3
                本条所指的重大变更包括但不限于：<br>9.3.1
                我们的服务模式发生重大变化，如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br>9.3.2 我们在所有权结构、组织架构等方面发生重大变化，如业务调整、破产并购等引起的所有者变更等；<br>9.3.3 个人信息共享、转让或公开披露的主要对象发生变化；<br>9.3.4 您参与个人信息处理方面的权利及其行使方式发生重大变化；<br>9.3.5 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；<br>9.3.6 个人信息安全影响评估报告表明存在高风险时。
            </p>
            <h2 class="f-bold">十、争议解决</h2>
            <p>本隐私协议的订立、履行和解释均适用中国法律。有关本隐私协议的任何争议，应由饭碗科技与用户秉承善意友好协商解决。若三十日内协商不成，双方同意将争议提交至宁波仲裁委员会依据其规则仲裁解决。<br>当您使用我们的服务，即意味着您已经同意本隐私协议所示之法律管辖及争议解决方式的有关约定。
            </p>
            <h2 class="f-bold">十一、联系我们</h2>
            <p>如果您对本隐私协议有任何疑问、意见或建议，请通过以下方式与我们联系：<br>邮箱：451833096@qq.com<br>地址：广东省深圳市宝安区兴业路2005号广兴源互联网创意园A座321<br>邮编：518000<br>电话：0755-82538389
            </p>
        </div>
    </div>

</main>
<footer class="footer">
    <div class="w-fixed">
        <div class="left">
            <div class="logo"></div>
            <p class="slogan">专致于打造好用的溯源系统</p>
            <div class="content">
                <p>客服时间：(8:30-19:00)</p>
                <p>商务合作：451833096@qq.com</p>
            </div>
        </div>
        <div class="nav">
            <div class="nav-item">
                <h1 class="title">产品功能</h1>
                <div class="link">
                    <a class="link-item" (click)="liveFunction()">直播</a>
                    <a class="link-item" (click)="materialManageComponent()">物料</a>
                    <a class="link-item" (click)="traceability()">溯源</a>

                </div>
            </div>
            <div class="nav-item">
                <h1 class="title">应用方案</h1>
                <div class="link">
                    <a class="link-item" (click)="vegetables()">蔬菜</a>
                    <a class="link-item" (click)="fruitTree()">果树</a>
                    <a class="link-item" (click)="breed()">养殖</a>

                </div>
            </div>
            <div class="nav-item">
                <h1 class="title">资源</h1>
                <div class="link">
                    <a class="link-item" (click)="helpCenter()">帮助中心</a>
                </div>
            </div>
            <div class="nav-item">
                <h1 class="title">公司</h1>
                <div class="link">
                    <a class="link-item" (click)="userAgreement()">用户协议</a>
                    <a class="link-item" (click)="about()">关于我们</a>
                </div>
            </div>
        </div>
    </div>
    <app-copy-right></app-copy-right>
</footer>
<div class="fixed-btn"><a href="javascript:void(0)" (click)="customerService()"  class="btn-kf" target="_blank">客服</a><a href="javascript:void(0)"
        class="btn-zd">置顶</a></div>
<!-- 注册弹窗s -->
<div id="dialog-reg" class="dialog dialog-login hidden">
    <a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
    <h1 class="login-title">注册账号</h1>
    <h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
    <div class="container">
        <div class="form">
            <div class="form-wrap">
                <p class="form-title">手机号注册</p>
                <div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
                <div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)"
                        class="btn-text">获取验证码</a></div>
                <div class="form-item"><input type="password" placeholder="密码（8-20位数字字母组合）"></div>
                <a href="javascript:void(0)" class="btn-reg dialog-close">立即注册</a>
            </div>
            <div class="agreement">
                <div class="check-btn"><span><input name="" type="checkbox" value=""></span>已阅读并同意<a (click)="customerService()"
                        class="btn-text">《饭碗用户协议》</a>及<a href="javascript:void(0)" (click)="privacyAgreement()" class="btn-text">《隐私协议》</a></div>
            </div>
        </div>
        <div class="ewm">
            <p class="ewm-title">微信快捷登录</p>
            <p class="ewm-img"><img src="images/ewm.png" alt=""></p>
            <p class="ewm-prompt">无需验证，快捷登录</p>
        </div>
    </div>
    <div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text"
            onClick="changeDiaolg('#dialog-reg','#dialog-login')">直接登录</a></div>
</div>
<!-- 注册弹窗e -->
<!-- 登录弹窗s -->
<div id="dialog-login" class="dialog dialog-login hidden">
    <a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
    <h1 class="login-title">登录饭碗账号</h1>
    <h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
    <div class="container">
        <div class="form">
            <div class="form-wrap">
                <p class="form-title">手机号登录</p>
                <div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
                <div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)"
                        class="btn-text">获取验证码</a></div>
                <div class="form-check">
                    <div class="check-box">
                        <div class="check-btn"><span><input name="" type="checkbox" value=""></span>自动登录</div>
                    </div>
                    <a href="javascript:void(0)" (click)="forgetPassword()" class="btn-forget-pwd">忘记密码！</a>
                </div>
                <a href="javascript:void(0)" class="btn-reg dialog-close">登录</a>
            </div>
        </div>
        <div class="ewm">
            <p class="ewm-title">微信快捷登录</p>
            <p class="ewm-img"><img src="images/ewm.png" alt=""></p>
            <p class="ewm-prompt">无需验证，快捷登录</p>
        </div>
    </div>
    <div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text"
            onClick="changeDiaolg('#dialog-login','#dialog-reg')">立即注册</a></div>
</div>
<!-- 登录弹窗e -->
<div id="dialog-bg" class="dialog-bg hidden"></div>
