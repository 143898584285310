
import { Injectable } from '@angular/core';
import axios from 'axios';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class AxiosService {

  
  /**
   * 请求environment KEY
   * @type
   */
  requestKey: any = {
    dashboard: 'dashboard',
  };
  constructor(public http: HttpClient) { }
  /**
   * 
   * @param url 请求地址
   * @param params 请求参数
   * @param reqKey 请求模块标识(上面有封装)
   */
  AxiosGet(url,params: Object = {},reqKey: string = null) {
    return new Promise((resolve, reject) => {
      let config = {
        // headers: 'Bearer' + ' ' + token['accessToken']
        params: params
      }
      axios.get(this.getUrl(url,reqKey),config).then((res) => {
        resolve(res.data)
      })
    })
  }
  /**
   * 
   * @param url 请求地址
   * @param params 请求参数
   * @param reqKey 请求模块标识(上面有封装)
   */
  AxiosPost(url,params:any,reqKey: string = null) {
    return new Promise((resolve, reject) => {
      // let config = {
      //   // headers: 'Bearer' + ' ' + token['accessToken']
      //   params:params
      // }
      // params
      axios.post(this.getUrl(url,reqKey),params).then((res) => {
        resolve(res.data)
      })
    })
  }

  /**
* get请求
* @param url
* @param param
* @param reqKey
* @returns {Promise<Object>}
*/
  get(url, params: Object = {}, reqKey: string = null) {
    // console.log("params",url,params);
    return this.http.get(this.getUrl(url, reqKey), params).toPromise();
  }

  /**
 * 根据moduleKey获取所对应的config配置信息
 * @param url
 * @param moduleKey
 * @returns {string}
 */
  getUrl(url: string, moduleKey: string): string {
    if (moduleKey == null) {
      return url;
    } else {
      let keyJson = JSON.parse(sessionStorage.getItem(moduleKey));
      // console.log(keyJson);
      if (keyJson['test']) {// 测试环境
        return keyJson['url'] + url;

      } else {// 生产环境
        return keyJson['pUrl'] + url;
      }
    }
  }

  /**
 * 设置本地缓存
 * @param result
 */
  setLocalStorage(result) {
    for (let key in result) {
      this.setSessionStorage(key, JSON.stringify(result[key]));
    }

  }
  /**
  * 设置缓存对象
  * @param key
  * @param value
  */
  setSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
  }


  /**
   * 清除缓存对象
   * @param key
   */
  clearSessionStorage(key: string = null) {
    if (key != null) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.clear();
    }
  }


}