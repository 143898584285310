<header class="header">
	<ul>
		<div class="logo"></div>
		<div class="menu">
			<li class="menu-item selected"><a class="menu-link" href="javascript:void(0)" (click)="index()">首页</a></li>
			<li class="menu-item drop-down-menu">
				<a class="menu-link" href="javascript:void(0)">产品功能</a>
				<div class="drop-down-menu-wrap">
					<div class="drop-down-menu-box">
						<div class="sub-menu">
							<ul>
								<!-- routerLink="/dashboard" -->
								<li><a  href="javascript:void(0)" (click)="materialManageComponent()">
										<p class="name">物料管理</p>
										<p>扫码即可出入库</p>
									</a></li>
								<li><a href="javascript:void(0)" (click)="liveFunction()">
										<p class="name">直播功能</p>
										<p>可对接各大直播平台</p>
									</a></li>
								<!--  -->
								<li><a href="javascript:void(0)"  (click)="dataAnalysis()">
										<p class="name">数据分析</p>
										<p>可导出生产数据报表</p>
									</a></li>
								<li><a href="javascript:void(0)" (click)="waterFertilizer()">
										<p class="name">水肥一体</p>
										<p>通过系统控制水肥系统</p>
									</a></li>
								<li><a href="javascript:void(0)" (click)="soilMoisture()">
										<p class="name">土壤墒情</p>
										<p>监控土壤墒情</p>
									</a></li>
								<li><a href="javascript:void(0)" (click)="climateMonitoring()">
										<p class="name">气候监控</p>
										<p>随时随地都能查看</p>
									</a></li>
								<li><a href="javascript:void(0)"  (click)="coldtracking()">
										<p class="name">冷链跟踪</p>
										<p>可查看冷链车相关情况</p>
									</a></li>
								<!-- <li><a href="dpjk.html">
										<p class="name">大屏监控功能</p>
										<p>通过大屏即知生产情况</p>
									</a></li> -->
								<li><a href="javascript:void(0)" (click)="traceability()">
										<p class="name">溯源功能</p>
										<p>可生成溯源二维码</p>
									</a></li>
							</ul>
						</div>
					</div>
				</div>
			</li>
			<li class="menu-item drop-down-menu">
				<a class="menu-link" href="javascript:void(0)">应用方案</a>
				<div class="drop-down-menu-wrap">
					<div class="drop-down-menu-box">
						<div class="sub-menu">
							<ul>

								<li><a href="javascript:void(0)" (click)="vegetables()">
										<p class="name">蔬菜</p>
										<p>从容生产</p>
									</a></li>
								<li><a href="javascript:void(0)" (click)="fruitTree()">
										<p class="name">果树</p>
										<p>高品质果树</p>
									</a></li>
								<li><a href="javascript:void(0)" (click)="breed()">
										<p class="name">养殖</p>
										<p>智慧养殖</p>
									</a></li>


							</ul>
						</div>
					</div>
				</div>
			</li>
			<li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="hardwareService()">硬件服务</a>
			</li>
			<!-- <li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="price()">价格</a></li> -->
		</div>
		<div class="right">
			<a href="javascript:void(0)" (click)="search()" class="btn-search">搜索</a>
			<a href="javascript:void(0)" class="btn-register" (click)="openDiaolg('#dialog-reg','.dialog-bg')">免费注册</a>
		</div>
	</ul>
</header>
<!-- 焦点图s -->
<div id="slideBox" class="slideBox">
	<div class="hd">
		<ul>
			<li></li>
			<li></li>
			<li></li>
		</ul>
	</div>
	<div class="bd">
		<ul>
			<li style="background: url(assets/images/carousel_img_01.jpg) 50% 0px no-repeat;"></li>
			<li style="background: url(assets/images/carousel_img_02.jpg) 50% 0px no-repeat;"></li>
		</ul>
		<a class="prev" href="javascript:void(0)"></a>
		<a class="next" href="javascript:void(0)"></a>
	</div>
</div>
<!-- 焦点图e -->
<main class="home">
	<section class="tjcd">
		<div class="w-fixed">
			<div class="card">
				<div class="card-item">
					<p class="name">精准物料</p>
					<p class="content">让您轻松管理</p>
				</div>
				<div class="card-item">
					<p class="name">智能追溯</p>
					<p class="content">提高产品知名度</p>
				</div>
				<div class="card-item">
					<p class="name">监控大屏</p>
					<p class="content">实时了解生产动态</p>
				</div>
				<div class="card-item">
					<p class="name">智能化管理</p>
					<p class="content">提高生产效率，降低生产成本</p>
				</div>
			</div>
		</div>
	</section>
	<section class="ptsm">
		<div class="w-fixed">
			<h1 class="header-title">免费应用农物联云平台初级版</h1>
			<div class="n-flex">
				<div class="left">
					<div class="title">强大的后台支撑</div>
					<div class="content">
						<p class="sub-title">强大的后台支撑</p>
						<p class="body">从物料、检测报告到最终生成溯源二维码<br />都可以为你实现</p>
					</div>
					<div class="content">
						<p class="sub-title">多系统实时上传</p>
						<p class="body">从PC端、移动端获取的溯源信息实时更新<br />虽说初级版，却不简单</p>
					</div>
					<a href="http://simpleagriculture.fine1.cn/" class="btn-view-details">查看详情</a>
				</div>
				<div class="img">
					<img src="assets/images/home_img_jy.jpg" alt="">
				</div>
			</div>
		</div>
	</section>
	<section class="ptsm ptsm-bg-gray">
		<div class="w-fixed">
			<h1 class="header-title">方便的物料管理系统</h1>
			<div class="n-flex">
				<div class="img">
					<img src="assets/images/home_img_wl.jpg" alt="">
				</div>
				<div class="left">
					<div class="title">清晰的数据记录</div>
					<div class="content">
						<p class="sub-title">从采购就开始跟踪</p>
						<p class="body">采购只需要用手机实时录入采购单<br />仓管入库只需确认数目，确认采购单即可</p>
					</div>
					<div class="content">
						<p class="sub-title">物料出入库方便</p>
						<p class="body">仓管只需扫描二维码进行出入库<br />仓库编号可根据实际情况，随时调整</p>
					</div>
					<a (click)="materialManageComponent()" href="javascript:void(0)" class="btn-view-details">查看详情</a>
				</div>
			</div>
		</div>
	</section>
	<section class="ptsm zb">
		<div class="w-fixed">
			<h1 class="header-title">实用的线上直播功能</h1>
			<div class="pic">
				<img src="assets/images/home_img_zb.jpg" alt="">
			</div>
			<div class="zb-content">通过摄像头就可以实时全天直播到各大直播平台，展示您农场的风貌，提升企业品牌价值</div>
			<div class="btn"><a (click)="liveFunction()" href="javascript:void(0)" class="btn-learn">现在了解</a></div>
		</div>
	</section>
	<section class="ptsm ptsm-bg-gray sy zb">
		<div class="w-fixed">
			<h1 class="header-title">专为农业公司打造，量身定做的溯源管理系统</h1>
			<div class="card clearfix">
				<div class="card-item">
					<img src="assets/images/home_icon_tx.png" alt="">
					<p class="name">完备的体系建设</p>
					<p class="content">根据质量控制标准，配套相关体系体制和相关管理标准</p>
				</div>
				<div class="card-item">
					<img src="assets/images/home_icon_yf.png" alt="">
					<p class="name">根据农场实际生产研发</p>
					<p class="content">开发系统人员到基地实际接触生产，最终落地本系统</p>
				</div>
				<div class="card-item">
					<img src="assets/images/home_icon_cz.png" alt="">
					<p class="name">操作方便实用</p>
					<p class="content">全程无纸质化操作，节省人工，导出的报表，方便仓管、财务等</p>
				</div>
			</div>
			<!-- <div class="btn"><a (click)="price()" href="javascript:void(0)" class="btn-learn">开始使用</a></div> -->
		</div>
	</section>
</main>
<footer class="footer">
	<div class="w-fixed">
		<div class="left">
			<div class="logo"></div>
			<p class="slogan">专致于打造好用的溯源系统</p>
			<div class="content">
				<p>客服时间：(8:30-19:00)</p>
				<p>商务合作：451833096@qq.com</p>
			</div>
		</div>

		<!-- this.router.navigate(['user', 1],{ fragment: 'top' });

		-->
		<div class="nav">
			<div class="nav-item">
				<h1 class="title">产品功能</h1>
				<div class="link">
					<!-- target="_blank" -->
					<a class="link-item" href="javascript:void(0)"  (click)="liveFunction()">直播</a>
					<a class="link-item"href="javascript:void(0)"  (click)="materialManageComponent()">物料</a>
					<a class="link-item" href="javascript:void(0)" (click)="traceability()">溯源</a>
				</div>
			</div>
			<div class="nav-item">
				<h1 class="title">应用方案</h1>
				<div class="link">
					<a class="link-item" href="javascript:void(0)" (click)="vegetables()">蔬菜</a>
					<a class="link-item" href="javascript:void(0)" (click)="fruitTree()">果树</a>
					<a class="link-item" href="javascript:void(0)" (click)="breed()">养殖</a>
				</div>
			</div>
			<div class="nav-item">
				<h1 class="title">资源</h1>
				<div class="link">
					<!-- <a class="link-item" href="hyal.html">行业案列</a> -->
					<a class="link-item" (click)="helpCenter()">帮助中心</a>
				</div>
			</div>
			<div class="nav-item">
				<h1 class="title">公司</h1>
				<div class="link">
					<a class="link-item" (click)="userAgreement()">用户协议</a>
					<!-- <a class="link-item">关于我们</a> -->
					<a class="link-item" (click)="about()">关于我们</a>
				</div>
			</div>
		</div>
	</div>
	<app-copy-right></app-copy-right>
</footer>
<div class="fixed-btn"><a (click)="customerService()" class="btn-kf" target="_blank">客服</a><a href="javascript:void(0)"
		class="btn-zd">置顶</a></div>
<!-- 注册弹窗s -->
<div id="dialog-reg" class="dialog dialog-login hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="login-title">注册账号</h1>
	<h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
	<div class="container">
		<div class="form">
			<div class="form-wrap">
				<p class="form-title">手机号注册</p>
				<div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
				<div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)"
						class="btn-text">获取验证码</a></div>
				<div class="form-item"><input type="password" placeholder="密码（8-20位数字字母组合）"></div>
				<a href="javascript:void(0)" class="btn-reg dialog-close">立即注册</a>
			</div>
			<div class="agreement">
				<div class="check-btn"><span><input name="" type="checkbox" value=""></span>已阅读并同意<a
						(click)="customerService()" class="btn-text">《饭碗用户协议》</a>及<a href="javascript:void(0)"
						(click)="privacyAgreement()" class="btn-text">《隐私协议》</a></div>
			</div>
		</div>
		<div class="ewm">
			<p class="ewm-title">微信快捷登录</p>
			<p class="ewm-img"><img src="assets/images/ewm.png" alt=""></p>
			<p class="ewm-prompt">无需验证，快捷登录</p>
		</div>
	</div>
	<div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text"
			onClick="changeDiaolg('#dialog-reg','#dialog-login')">直接登录</a></div>
</div>
<!-- 注册弹窗e -->
<!-- 登录弹窗s -->
<div id="dialog-login" class="dialog dialog-login hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="login-title">登录饭碗账号</h1>
	<h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
	<div class="container">
		<div class="form">
			<div class="form-wrap">
				<p class="form-title">手机号登录</p>
				<div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
				<div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)"
						class="btn-text">获取验证码</a></div>
				<div class="form-check">
					<div class="check-box">
						<div class="check-btn"><span><input name="" type="checkbox" value=""></span>自动登录</div>
					</div>
					<a href="javascript:void(0)" (click)="forgetPassword()" class="btn-forget-pwd">忘记密码！</a>
				</div>
				<a href="javascript:void(0)" class="btn-reg dialog-close">登录</a>
			</div>
		</div>
		<div class="ewm">
			<p class="ewm-title">微信快捷登录</p>
			<p class="ewm-img"><img src="assets/images/ewm.png" alt=""></p>
			<p class="ewm-prompt">无需验证，快捷登录</p>
		</div>
	</div>
	<div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text"
			onClick="changeDiaolg('#dialog-login','#dialog-reg')">立即注册</a></div>
</div>
<!-- 登录弹窗e -->
<div id="dialog-bg" class="dialog-bg hidden"></div>
