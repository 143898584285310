<header class="header">
	<ul>
		<div class="logo"></div>
		<div class="menu">
			<li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="index()">首页</a></li>
			<li class="menu-item selected drop-down-menu">
				<a class="menu-link" href="javascript:void(0)">产品功能</a>
				<div class="drop-down-menu-wrap">
					<div class="drop-down-menu-box">
						<div class="sub-menu">
							<ul>
								<li><a (click)="materialManageComponent()"><p class="name">物料管理</p><p>扫码即可出入库</p></a></li>
								<li><a (click)="liveFunction()"><p class="name">直播功能</p><p>可对接各大直播平台</p></a></li>
								<li><a (click)="dataAnalysis()"><p class="name">数据分析</p><p>可导出生产数据报表</p></a></li>
								<li><a (click)="waterFertilizer()"><p class="name">水肥一体</p><p>通过系统控制水肥系统</p></a></li>
								<li><a (click)="soilMoisture()"><p class="name">土壤墒情</p><p>监控土壤墒情</p></a></li>
								<li class="selected"><a (click)="climateMonitoring()"><p class="name">气候监控</p><p>随时随地都能查看</p></a></li>
								<li><a (click)="coldtracking()"><p class="name">冷链跟踪</p><p>可查看冷链车相关情况</p></a></li>
								<li><a (click)="traceability()"><p class="name">溯源功能</p><p>可生成溯源二维码</p></a></li>
							</ul>
						</div>
					</div>
				</div>
			</li>
			<li class="menu-item drop-down-menu">
				<a class="menu-link" href="javascript:void(0)">应用方案</a>
				<div class="drop-down-menu-wrap">
					<div class="drop-down-menu-box">
						<div class="sub-menu">
							<ul>
								<li><a href="javascript:void(0)" (click)="vegetables()">
									<p class="name">蔬菜</p>
									<p>从容生产</p>
								</a></li>
							<li><a  href="javascript:void(0)" (click)="fruitTree()">
									<p class="name">果树</p>
									<p>高品质果树</p>
								</a></li>
							<li><a  href="javascript:void(0)" (click)="breed()">
									<p class="name">养殖</p>
									<p>智慧养殖</p>
								</a></li>
							</ul>
						</div>
					</div>
				</div>
			</li>
			<li class="menu-item"><a class="menu-link"  href="javascript:void(0)" (click)="hardwareService()">硬件服务</a></li>
			<!-- <li class="menu-item"><a class="menu-link"   href="javascript:void(0)" (click)="price()">价格</a></li> -->
		</div>
		<div class="right">
			<a href="javascript:void(0)" (click)="search()" class="btn-search">搜索</a>
			<a href="javascript:void(0)" class="btn-register" onClick="openDiaolg('#dialog-reg','.dialog-bg')">免费注册</a>
		</div>
	</ul>
</header>
<main class="qhjk">
	<section class="banner">
		<div class="w-fixed">
			<div class="pic"><img src="assets/images/qhjk_banner_img.png" alt=""></div>
			<div class="content">
				<div class="title">气象监控</div>
				<div class="body">随时关注农场气候动态，预防农场病害</div>
			</div>
		</div>
	</section>
	<section class="xtgn">
		<div class="w-fixed">
			<h1 class="base-header-title">系统功能</h1>
			<h2 class="base-header-sub-title">实时反馈，有效预防</h2>
			<div class="list">
				<div class="list-item">
					<p class="name">气象监测</p>
					<p class="content">农业环境监测系统能够全天候，连续、自动地监测，温度、湿度、PM2.5、PM10、大气压力、风速、风向、噪声、雨量、土壤、蒸发量、二氧化硫、二氧化氮、臭氧、一氧化碳等实时变化情况，迅速准确的收集、处理监测数据，实现区域气候质量的在线自动监测</p>
				</div>
				<div class="list-item">
					<p class="name">预警功能</p>
					<p class="content">通过传感器上传的数据，监控终端汇聚以及过滤，当某一项数据超过阙值时，系统立即实时转发给相关管理人员手机app上。提醒管理人员应该进行什么样的预防措施。</p>
				</div>
			</div>
		</div>
	</section>
	<section class="hjjk">
		<div class="w-fixed">
			<h1 class="base-header-title">环境监控</h1>
			<h2 class="base-header-sub-title">系统反馈环境数据的一角</h2>
			<div class="pic">
				<img src="assets/images/qhjk_img_hjjk.jpg" alt="">
				<p class="name">通过传感器将环境数据实时反馈到云平台</p>
			</div>
		</div>
	</section>

</main>
<footer class="footer">
	<div class="w-fixed">
		<div class="left">
			<div class="logo"></div>
			<p class="slogan">专致于打造好用的溯源系统</p>
			<div class="content">
				<p>客服时间：(8:30-19:00)</p>
				<p>商务合作：451833096@qq.com</p>
			</div>
		</div>
		<div class="nav">
			<div class="nav-item">
				<h1 class="title">产品功能</h1>
				<div class="link">
					<a class="link-item" (click)="liveFunction()">直播</a>
					<a class="link-item" (click)="materialManageComponent()">物料</a>
					<a class="link-item" (click)="traceability()">溯源</a>

				</div>
			</div>
			<div class="nav-item">
				<h1 class="title">应用方案</h1>
				<div class="link">
					<a class="link-item" (click)="vegetables()">蔬菜</a>
					<a class="link-item" (click)="fruitTree()">果树</a>
					<a class="link-item" (click)="breed()">养殖</a>
				</div>
			</div>
			<div class="nav-item">
				<h1 class="title">资源</h1>
				<div class="link">
					<!-- <a class="link-item" href="hyal.html">行业案列</a> -->
					<a class="link-item" (click)="helpCenter()">帮助中心</a>
				</div>
			</div>
			<div class="nav-item">
				<h1 class="title">公司</h1>
				<div class="link">
					<a class="link-item"  (click)="userAgreement()">用户协议</a>
					<!-- <a class="link-item">关于我们</a> -->
					<a class="link-item"  (click)="about()">关于我们</a>
				</div>
			</div>
		</div>
	</div>
	<app-copy-right></app-copy-right>
</footer>
<div class="fixed-btn"><a (click)="customerService()" class="btn-kf" target="_blank">客服</a><a href="javascript:void(0)" class="btn-zd">置顶</a></div>
<!-- 注册弹窗s -->
<div id="dialog-reg" class="dialog dialog-login hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="login-title">注册账号</h1>
	<h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
	<div class="container">
		<div class="form">
			<div class="form-wrap">
				<p class="form-title">手机号注册</p>
				<div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
				<div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)" class="btn-text">获取验证码</a></div>
				<div class="form-item"><input type="password" placeholder="密码（8-20位数字字母组合）"></div>
				<a href="javascript:void(0)" class="btn-reg dialog-close">立即注册</a>
			</div>
			<div class="agreement">
				<div class="check-btn"><span><input name="" type="checkbox" value=""></span>已阅读并同意<a (click)="customerService()"
					class="btn-text">《饭碗用户协议》</a>及<a href="javascript:void(0)" (click)="privacyAgreement()" class="btn-text">《隐私协议》</a></div>
			  </div>
		</div>
		<div class="ewm">
			<p class="ewm-title">微信快捷登录</p>
			<p class="ewm-img"><img src="assets/images/ewm.png" alt=""></p>
			<p class="ewm-prompt">无需验证，快捷登录</p>
		</div>
	</div>
	<div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text" onClick="changeDiaolg('#dialog-reg','#dialog-login')">直接登录</a></div>
</div>
<!-- 注册弹窗e -->
<!-- 登录弹窗s -->
<div id="dialog-login" class="dialog dialog-login hidden">
	<a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
	<h1 class="login-title">登录饭碗账号</h1>
	<h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
	<div class="container">
		<div class="form">
			<div class="form-wrap">
				<p class="form-title">手机号登录</p>
				<div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
				<div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)" class="btn-text">获取验证码</a></div>
				<div class="form-check">
					<div class="check-box"><div class="check-btn"><span><input name="" type="checkbox" value=""></span>自动登录</div></div>
					<a href="javascript:void(0)" (click)="forgetPassword()" class="btn-forget-pwd">忘记密码！</a>
				</div>
				<a href="javascript:void(0)" class="btn-reg dialog-close">登录</a>
			</div>
		</div>
		<div class="ewm">
			<p class="ewm-title">微信快捷登录</p>
			<p class="ewm-img"><img src="assets/images/ewm.png" alt=""></p>
			<p class="ewm-prompt">无需验证，快捷登录</p>
		</div>
	</div>
	<div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text" onClick="changeDiaolg('#dialog-login','#dialog-reg')">立即注册</a></div>
</div>
<!-- 登录弹窗e -->
<div id="dialog-bg" class="dialog-bg hidden"></div>
