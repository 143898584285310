import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColdtrackingComponent } from './coldtracking.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [ColdtrackingComponent]
})
export class ColdtrackingModule { }
