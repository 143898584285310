import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HardwareServiceComponent } from './hardwareService.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [HardwareServiceComponent]
})
export class HardwareServiceModule { }
