import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { MaterialManageComponent } from './materialManage/materialManage.component';
import { LiveFunctionComponent } from './liveFunction/liveFunction.component';
import { PriceComponent } from './price/price.component';
import { HardwareServiceComponent } from './hardwareService/hardwareService.component';
import { AboutComponent } from './about/about.component';
import { DataAnalysisComponent } from './dataAnalysis/dataAnalysis.component';
import { WaterFertilizerComponent } from './waterFertilizer/waterFertilizer.component';
import { SoilMoistureComponent } from './soilMoisture/soilMoisture.component';
import { ClimateMonitoringComponent } from './climateMonitoring/climateMonitoring.component';
import { ColdtrackingComponent } from './coldtracking/coldtracking.component';
import { TraceabilityComponent } from './traceability/traceability.component';
import { BuyBowComponent } from './buyBow/buyBow.component';
import { ContinuePaymentComponent } from './continuePayment/continuePayment.component';
import { VegetablesComponent } from './vegetables/vegetables.component';
import { FruitTreeComponent } from './fruit-tree/fruit-tree.component';
import { BreedComponent } from './breed/breed.component';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { HelpCenterComponent } from './help-center/help-center.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { SearchComponent } from './search/search.component';
import { HardwareBuyComponent } from './hardware-buy/hardware-buy.component';
import { PrivacyAgreementComponent } from './privacy-agreement/privacy-agreement.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { MyOrderComponent } from './my-order/my-order.component';
import { MyAddressComponent } from './my-address/my-address.component';
import { HardwarePriceComponent } from './hardware-price/hardware-price.component';
import { BuyBowMaterialComponent } from './buy-bow-material/buy-bow-material.component';

const routes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },

  {
  
    path: 'index', component: IndexComponent 
   },
   {
    path: 'materialManageComponent', component: MaterialManageComponent 
   },
   {
    path: 'liveFunction', component: LiveFunctionComponent 
   },
   {
    path: 'price', component: PriceComponent 
   },
   {
    path: 'hardwareService', component: HardwareServiceComponent 
   },
   {
    path: 'about', component: AboutComponent 
   },

   {
    path: 'dataAnalysis', component: DataAnalysisComponent 
   },
   
   {
    path: 'waterFertilizer', component: WaterFertilizerComponent 
   },
   {
    path: 'soilMoisture', component: SoilMoistureComponent 
   },
   {
    path: 'climateMonitoring', component: ClimateMonitoringComponent 
   },

   {
    path: 'coldtracking', component: ColdtrackingComponent 
   },
   {
    path: 'traceability', component: TraceabilityComponent 
   },
   {
    path: 'buyBow', component: BuyBowComponent 
   },
   {
    path: 'continuePayment', component: ContinuePaymentComponent 
   },
   {
    path: 'vegetables', component: VegetablesComponent 
   },
   {
    path: 'fruitTree', component: FruitTreeComponent 
   },
   {
    path: 'breed', component: BreedComponent 
   },
   {
    path: 'customerService', component: CustomerServiceComponent 
   },

   {
    path: 'helpCenter', component: HelpCenterComponent 
   },
   {
    path: 'userAgreement', component: UserAgreementComponent 
   },
   {
    path: 'search', component: SearchComponent 
   },
   {
    path: 'hardwareBuy', component: HardwareBuyComponent 
   },
   {
    path: 'privacyAgreement', component: PrivacyAgreementComponent 
   },
   {
    path: 'forgetPassword', component: ForgetPasswordComponent 
   },
   {
    path: 'accountInfo', component: AccountInfoComponent 
   },
   {
    path: 'myOrder', component: MyOrderComponent 
   },
   {
    path: 'myAddress', component: MyAddressComponent 
   },
   {
    path: 'hardwarePrice', component: HardwarePriceComponent 
   },
   {
    path: 'buyBowMaterial', component: BuyBowMaterialComponent 
   },

  //  BuyBowMaterialComponent
];


@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0],
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
