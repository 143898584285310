import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { IndexModule } from './index/index.module';
import { MaterialManageModule } from './materialManage/materialManage.module';
import { LiveFunctionModule } from './liveFunction/liveFunction.module';
import { PriceModule } from './price/price.module';
import { HardwareServiceModule } from './hardwareService/hardwareService.module';
import { AboutModule } from './about/about.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { DataAnalysisModule } from './dataAnalysis/dataAnalysis.module';
import { WaterFertilizerModule } from './waterFertilizer/waterFertilizer.module';
import { SoilMoistureModule } from './soilMoisture/soilMoisture.module';
import { ClimateMonitoringModule } from './climateMonitoring/climateMonitoring.module';
import { ColdtrackingModule } from './coldtracking/coldtracking.module';
import { TraceabilityModule } from './traceability/traceability.module';
import { BuyBowModule } from './buyBow/buyBow.module';
import { ContinuePaymentModule } from './continuePayment/continuePayment.module';
import { SharedModule } from './shared/shared.module';
import { VegetablesModule } from './vegetables/vegetables.module';
import { FruitTreeModule } from './fruit-tree/fruit-tree.module';
import { BreedComponent } from './breed/breed.component';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { HelpCenterComponent } from './help-center/help-center.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { SearchComponent } from './search/search.component';
import { HardwareBuyComponent } from './hardware-buy/hardware-buy.component';
import { PrivacyAgreementComponent } from './privacy-agreement/privacy-agreement.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { MyOrderComponent } from './my-order/my-order.component';
import { MyAddressComponent } from './my-address/my-address.component';
import { HardwarePriceComponent } from './hardware-price/hardware-price.component';
import { BuyBowMaterialComponent } from './buy-bow-material/buy-bow-material.component';
import { CopyRightComponent } from './copy-right/copy-right.component';



@NgModule({
  declarations: [
    AppComponent, HardwarePriceComponent, BreedComponent, CustomerServiceComponent, HelpCenterComponent, UserAgreementComponent, SearchComponent, HardwareBuyComponent, PrivacyAgreementComponent, ForgetPasswordComponent, AccountInfoComponent, MyOrderComponent, MyAddressComponent, BuyBowMaterialComponent,

  ],
  imports: [
    SharedModule,
    VegetablesModule,
    BrowserModule,
    AppRoutingModule,
    IndexModule,
    MaterialManageModule,
    LiveFunctionModule,
    PriceModule,
    HardwareServiceModule,
    AboutModule,
    DataAnalysisModule,
    WaterFertilizerModule,
    SoilMoistureModule,
    ClimateMonitoringModule,
    ColdtrackingModule,
    TraceabilityModule,
    BuyBowModule,
    ContinuePaymentModule,
    FruitTreeModule,

  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
