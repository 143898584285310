<header class="header">
    <ul>
        <div class="logo"></div>
        <div class="menu">
            <li class="menu-item"><a class="menu-link"  href="javascript:void(0)"  (click)="index()">首页</a></li>
            <li class="menu-item drop-down-menu">
                <a class="menu-link" href="javascript:void(0)">产品功能</a>
                <div class="drop-down-menu-wrap">
                    <div class="drop-down-menu-box">
                        <div class="sub-menu">
                            <ul>
                                <li><a href="javascript:void(0)" (click)="materialManageComponent()">
                                        <p class="name">物料管理</p>
                                        <p>扫码即可出入库</p>
                                    </a></li>
                                <li><a  href="javascript:void(0)" (click)="liveFunction()">
                                        <p class="name">直播功能</p>
                                        <p>可对接各大直播平台</p>
                                    </a></li>
                                <li><a  href="javascript:void(0)" (click)="dataAnalysis()">
                                        <p class="name">数据分析</p>
                                        <p>可导出生产数据报表</p>
                                    </a></li>
                                <li><a  href="javascript:void(0)" (click)="waterFertilizer()">
                                        <p class="name">水肥一体</p>
                                        <p>通过系统控制水肥系统</p>
                                    </a></li>
                                <li><a href="javascript:void(0)" (click)="soilMoisture()">
                                        <p class="name">土壤墒情</p>
                                        <p>监控土壤墒情</p>
                                    </a></li>
                                <li><a href="javascript:void(0)" (click)="climateMonitoring()">
                                        <p class="name">气候监控</p>
                                        <p>随时随地都能查看</p>
                                    </a></li>
                                <li><a href="javascript:void(0)" (click)="coldtracking()">
                                        <p class="name">冷链跟踪</p>
                                        <p>可查看冷链车相关情况</p>
                                    </a></li>
                                <li class="selected"><a  href="javascript:void(0)" (click)="traceability()">
                                        <p class="name">溯源功能</p>
                                        <p>可生成溯源二维码</p>
                                    </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
            <li class="menu-item selected drop-down-menu">
                <a class="menu-link" href="javascript:void(0)">应用方案</a>
                <div class="drop-down-menu-wrap">
                    <div class="drop-down-menu-box">
                        <div class="sub-menu">
                            <ul>
                                <li><a href="javascript:void(0)" (click)="vegetables()">
                                    <p class="name">蔬菜</p>
                                    <p>从容生产</p>
                                </a></li>
                            <li class="selected"><a href="javascript:void(0)" (click)="fruitTree()">
                                    <p class="name">果树</p>
                                    <p>高品质果树</p>
                                </a></li>
                            <li><a  href="javascript:void(0)" (click)="breed()">
                                    <p class="name">养殖</p>
                                    <p>智慧养殖</p>
                                </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
            <li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="hardwareService()">硬件服务</a></li>
            <!-- <li class="menu-item"><a class="menu-link" href="javascript:void(0)" (click)="price()">价格</a></li> -->
        </div>
        <div class="right">
            <a  href="javascript:void(0)" (click)="search()" class="btn-search">搜索</a>
            <a href="javascript:void(0)" class="btn-register" onClick="openDiaolg('#dialog-reg','.dialog-bg')">免费注册</a>
            <!-- 我的s -->
            <div class="mine">
                <div class="avatar"><i class="icon-avatar"></i><i class="icon-arrow"></i></div>
                <div class="mine-popup">
                    <div class="mine-popup-wrap">
                        <div class="user-info">
                            <p class="tel">13352928035</p>
                            <p class="version">免费版<span class="btn-upgrade">升级</span></p>
                        </div>
                        <div class="nav">
                            <a href="javascript:void(0)" (click)="myOrder()" class="nav-item"><i class="icon icon-dd"></i>我的订单</a>
                            <a  href="javascript:void(0)" (click)="myAddress()"  class="nav-item"><i class="icon icon-dz"></i>我的地址</a>
                            <a href="javascript:void(0)"   (click)="accountInfo()" class="nav-item"><i class="icon icon-zh"></i>账户信息</a>
                            <a href="javascript:void(0)" (click)="index()" class="nav-item" id="aqtc"><i class="icon icon-tc"></i>安全退出</a>
						</div>
                    </div>
                </div>
            </div>
            <!-- 我的e -->
        </div>
    </ul>
</header>
<main class="gsfa">
    <section class="banner">
        <div class="w-fixed">
            <div class="content">
                <div class="title">智慧果树种植解决方案</div>
                <div class="body">通过公司工作人员下基地研究，最终研发出最适合果树种植的系统方案</div>
                <a href="javascript:void(0)" (click)="customerService()" class="btn-green">合作咨询</a>
            </div>
        </div>
    </section>
    <section class="row fajj">
        <div class="w-fixed">
            <h1 class="base-header-title">方案简介</h1>
            <h2 class="base-header-sub-title">果树与蔬菜系统体现在细节处</h2>
            <div class="list">
                <div class="list-item">
                    <p class="name">监测与控制</p>
                    <p class="content">
                        系统通过设备获取植物实时的生长环境信息，如通过各个类型的传感器监测土壤水分、土壤温度、空气温度、空气湿度、光照强度等参数。实现基地所有监测点的信息获取、管理、动态显示和分析处理以直观的图表和曲线方式显示给用户，并根据这些数据信息反馈，进行自动灌溉、自动降温、自动进行液体施肥，自动喷药等控制。
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class="row bg-gray">
        <div class="w-fixed">
            <h1 class="base-header-title">方案亮点</h1>
            <h2 class="base-header-sub-title">从实际出发，做智慧的事</h2>
            <div class="list">
                <div class="list-item">
                    <p class="name">环境监测</p>
                    <p class="content">其中含有土壤墒情监测、智能虫情监测、智能孢子监测、苗情监测、气象环境监测和视频直播。</p>
                </div>
                <div class="list-item">
                    <p class="name">水肥一体联动系统</p>
                    <p class="content">
                        通过可控管道系统供水、供肥，使水肥相融后通过管道、喷枪或喷头喷灌，均匀、定时、定量的喷洒在作物发育生长区域，使主要发育生长区域土壤始终保持适宜的含水量，同时根据不同的作物的需肥特点，根据土壤的参数，把相应的水和肥按比例提供给作物。
                    </p>
                </div>
                <div class="list-item">
                    <p class="name">生产方案</p>
                    <p class="content">系统可根据不同的树龄，进行不同施肥浇水方案，以保证每棵树得到更好的照料，结出让人满意得果实。</p>
                </div>
                <div class="list-item">
                    <p class="name">溯源系统</p>
                    <p class="content">
                        通过系统以及农场工作人员扫描二维码操作自己的相关农事记录，实现生产的细致管理，得到大量的数据，进行生产分析，以提高生产质量与作物产量。同时对接销售端与客户端，提高农场的品牌知名性。</p>
                </div>
            </div>
        </div>
    </section>
    <section class="row">
        <div class="w-fixed">
            <h1 class="base-header-title">方案优势</h1>
            <h2 class="base-header-sub-title">通过大量的实验与测试，选择最优硬件设备</h2>
            <div class="list">
                <div class="list-item">
                    <p class="name">我们的优势</p>
                    <p class="content">
                        (1) 软件系统基于自己成熟的物联网通用平台, 系统运行稳定可靠, 性能优异。采用云计算SAAS模式, 客户无需架设专门的服务器和网络系统, 节省投资, 软件系统部署和维护简便。<br />
                        (2) 集成的视频监控功能, 视频与环境监控无缝集成, 实现真正意义的可视化监控管理。<br />
                        (3) 集成度高, 前端监控设备选用文朗润诚自主研发的一体化温室智能监控终端, 集成度高, 无需专门安装, 插电即用, 后期维护简便。<br />
                        (4) 硬件可靠性高, 防尘、防水, 可以安装在户外,适应阴雨潮湿等恶劣环境。已经通过实践考验。<br />
                        (5) 软件系统功能完善, 专门为农业客户定制的系统界面, 界面友好, 操作便捷。 集成的视频监控功能,实现便捷的远程可视化管理。<br />
                        (6) 兼容性好, 通过配置, 可以连接客户已有的不同厂商品牌的采集和控制设备。灵活的配置功能, 可以满足不同客户的个性化需求。<br />
                        (7) 支持计算机、手机、Pad等多种终端访问, 提供智能手机客户端软件, 可以随时随地监控温室环境。集成微信、手机短信等多种展现和交互方式, 信息传递及时、便捷。<br />
                        (8) 配套的农业知识库、农产品溯源系统等附加功能。<br />
                        (9) 系统扩展性好, 支持二次开发, 可以与客户现有其它信息系统深度集成。
                    </p>
                </div>
            </div>
        </div>
    </section>

</main>
<footer class="footer">
    <div class="w-fixed">
        <div class="left">
            <div class="logo"></div>
            <p class="slogan">专致于打造好用的溯源系统</p>
            <div class="content">
                <p>客服时间：(8:30-19:00)</p>
                <p>商务合作：451833096@qq.com</p>
            </div>
        </div>
        <div class="nav">
            <div class="nav-item">
                <h1 class="title">产品功能</h1>
                <div class="link">
                    <a class="link-item" (click)="liveFunction()">直播</a>
                    <a class="link-item" (click)="materialManageComponent()">物料</a>
                    <a class="link-item" (click)="traceability()">溯源</a>
                </div>
            </div>
            <div class="nav-item">
                <h1 class="title">应用方案</h1>
                <div class="link">
                    <a class="link-item" (click)="vegetables()">蔬菜</a>
					<a class="link-item" (click)="fruitTree()">果树</a>
					<a class="link-item" (click)="breed()">养殖</a>
                </div>
            </div>
            <div class="nav-item">
                <h1 class="title">资源</h1>
                <div class="link">
                    <a class="link-item" (click)="helpCenter()">帮助中心</a>
                </div>
            </div>
            <div class="nav-item">
                <h1 class="title">公司</h1>
                <div class="link">
                    <a class="link-item" (click)="userAgreement()">用户协议</a>
                    <a class="link-item" (click)="about()">关于我们</a>
                </div>
            </div>
        </div>
    </div>
    <app-copy-right></app-copy-right>
</footer>
<div class="fixed-btn"><a (click)="customerService()" class="btn-kf" target="_blank">客服</a><a href="javascript:void(0)" class="btn-zd">置顶</a></div>
<!-- 注册弹窗s -->
<div id="dialog-reg" class="dialog dialog-login hidden">
    <a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
    <h1 class="login-title">注册账号</h1>
    <h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
    <div class="container">
        <div class="form">
            <div class="form-wrap">
                <p class="form-title">手机号注册</p>
                <div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
                <div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)"
                        class="btn-text">获取验证码</a></div>
                <div class="form-item"><input type="password" placeholder="密码（8-20位数字字母组合）"></div>
                <a href="javascript:void(0)" class="btn-reg dialog-close">立即注册</a>
            </div>
            <div class="agreement">
                <div class="check-btn"><span><input name="" type="checkbox" value=""></span>已阅读并同意<a (click)="customerService()"
                    class="btn-text">《饭碗用户协议》</a>及<a href="javascript:void(0)" (click)="privacyAgreement()" class="btn-text">《隐私协议》</a></div>
              </div>
        </div>
        <div class="ewm">
            <p class="ewm-title">微信快捷登录</p>
            <p class="ewm-img"><img src="images/ewm.png" alt=""></p>
            <p class="ewm-prompt">无需验证，快捷登录</p>
        </div>
    </div>
    <div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text"
            onClick="changeDiaolg('#dialog-reg','#dialog-login')">直接登录</a></div>
</div>
<!-- 注册弹窗e -->
<!-- 登录弹窗s -->
<div id="dialog-login" class="dialog dialog-login hidden">
    <a href="javascript:void(0)" class="btn-dialog-close dialog-close">关闭</a>
    <h1 class="login-title">登录饭碗账号</h1>
    <h2 class="login-sub-title">方便免费体验简化版本的溯源系统以及购买</h2>
    <div class="container">
        <div class="form">
            <div class="form-wrap">
                <p class="form-title">手机号登录</p>
                <div class="form-item"><input type="text" placeholder="输入您的手机号"></div>
                <div class="form-item form-code"><input type="text" placeholder="输入您的验证码"><a href="javascript:void(0)"
                        class="btn-text">获取验证码</a></div>
                <div class="form-check">
                    <div class="check-box">
                        <div class="check-btn"><span><input name="" type="checkbox" value=""></span>自动登录</div>
                    </div>
                    <a href="javascript:void(0)" (click)="forgetPassword()" class="btn-forget-pwd">忘记密码！</a>
                </div>
                <a href="javascript:void(0)" class="btn-reg dialog-close">登录</a>
            </div>
        </div>
        <div class="ewm">
            <p class="ewm-title">微信快捷登录</p>
            <p class="ewm-img"><img src="images/ewm.png" alt=""></p>
            <p class="ewm-prompt">无需验证，快捷登录</p>
        </div>
    </div>
    <div class="login-footer-text">还未有账号<a href="javascript:void(0)" class="btn-text"
            onClick="changeDiaolg('#dialog-login','#dialog-reg')">立即注册</a></div>
</div>
<!-- 登录弹窗e -->
<div id="dialog-bg" class="dialog-bg hidden"></div>
